import styles from "./CopyItem.module.css";
import { MdContentCopy } from "react-icons/md";

const CopyItem = ({ item, home, showDetail }) => {
  const convertCurrencyStringToNumber = (currencyString) => {
    // Remove commas and non-numeric characters except for digits
    const numericString = currencyString.replace(/[^0-9]/g, '');
    // Convert the numeric string to a number
    return parseInt(numericString, 10);
  };
  const onCopyClicked = () => {
    if (item.amount) {
      navigator?.clipboard?.writeText(convertCurrencyStringToNumber(item?.value));

    } else {
      navigator?.clipboard?.writeText(item?.value);
    }

  }
  return (
    <div className={`${styles.bankDetailItem} ${home ? styles.homeMsg : ""}`}>
      {<div className={styles.divWrapper} style={{ textAlign: "left" }}>
        <span className={styles.copyLabel}>{item.label}</span>
        {/* <br /> */}
        <span
          className={`${styles.copyValue} ${item.fontSmall && styles.smallValue
            } ${item.redText && styles.redText}`}
        >
          {item.value}
        </span>
      </div>
      }
      {item?.copy && <span className={styles.copyButton} onClick={onCopyClicked}>
        <MdContentCopy size={20} color="#FFEB72" />
        <span>Sao chép</span>
      </span>
      }
    </div>
  );
};

export default CopyItem;