import { useContext, useState } from "react";
import styles from "./SingleGameListing.module.css";
// import { APICategoryGameList } from '../../../helpers/APIs/GamesAPI';
import { useNavigate } from "react-router-dom";
import PlayGameModal from "../../HomeComponents/PlayerGameModal/PlayerGameModal";
import UserContext from "../../../helpers/Context/user-context";
// import DagaGameSection from "../DagaGameSection/DagaGameSection";

// import { useQuery } from "react-query";
// import NoRecordFound from "../../../NoRecordFound/NoRecordFound";
// import MyLoader from "../../../MyLoader";
// import { getGameItems } from "../../../../helpers/APIs/GameAPIs";

const SingleGameList = ({ games, gameLink }) => {
  // console.log(gameLink);
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();

  const navigate = useNavigate();
  const ctx = useContext(UserContext);

  // const openGameModal = async (game) => {
  //   if (!ctx.user) {
  //     navigate("/login");
  //   } else if (ctx.user && ctx?.userInfo && ctx?.userInfo?.user_id && ctx?.userInfo?.user_name) {
  //     setPlayGameState(true);
  //     setSingleGameModal(game);
  //   } else if (ctx.user && ctx?.userInfo && !ctx?.userInfo?.user_id && !ctx?.userInfo?.user_name) {
  //     navigate("/deposit");
  //   }
  // };
  // open game Modal
  const openGameModal = async (game) => {
    if (!ctx.user) {
      navigate("/login");
    } else {
      setPlayGameState(true);
      setSingleGameModal(game);
    }
  };

  return (
    <div className={styles.gamesListWrapper}>
      {
        <div className={styles.gamesBodyList}>
          {games && games?.length > 0
            ? games?.map((game, index) => (
              <div
                key={game.game_id}
                className={styles.gameImageWrapper}
                onClick={() => openGameModal(game)}
              // onClick={() =>
              //   gameLink &&
              //   window.open(
              //     ctx.refCode
              //       ? gameLink
              //       : `https://www.sv368.casino/register`,
              //     "_blank"
              //   )
              // }
              >
              </div>
            ))
            : games && (
              <div className={styles.noImage}>
                <h4>Không tìm thấy trò chơi nào</h4>
              </div>
            )}
        </div>
      }
      {playGameState && (
        <PlayGameModal
          playGameState={playGameState}
          setPlayGameState={setPlayGameState}
          singleGameModal={singleGameModal}
          setSingleGameModal={setSingleGameModal}
        />
      )}
    </div>
  );
};

export default SingleGameList;
