// import gA67a8a9 from '../assets/Captchas/gA67a8a9.png';
// import Ga67a8Z9 from '../assets/Captchas/Ga67a8Z9.png';
// import SV368G from '../assets/Captchas/SV368G.png';
// import SV368Ga from '../assets/Captchas/SV368Ga.png';
import G1798 from '../assets/Captchas/G1798.webp';
import G2421 from '../assets/Captchas/G2421.webp';
import G3812 from '../assets/Captchas/G3812.webp';
import G5621 from '../assets/Captchas/G5621.webp';
import G8554 from '../assets/Captchas/G8554.webp';
import G6984 from '../assets/Captchas/G6984.webp';


const captchas = [
    { image: G1798, value: "1798" },
    { image: G2421, value: "2421" },
    { image: G3812, value: "3812" },
    { image: G5621, value: "5621" },
    { image: G8554, value: "8554" },
    { image: G6984, value: "6984" }

];

export default captchas;