import { useContext, useRef, useState } from "react";
import styles from "./Footer.module.css";
import Footer1 from "./Footer1/Footer1";
import Up from "../../assets/FooterIcons/Up.webp";
import Down from "../../assets/FooterIcons/Down.webp";
import FooterDetails from "./FooterDetails/FooterDetails";
import UserContext from "../../helpers/Context/user-context";

const mainFooterLinks = [
  {
    id: 1,
    name: "Giới Thiệu",
    link: "#",
  },
  { id: 2, name: "Trợ Giúp", link: "#" },
  {
    id: 3,
    name: "Điều Khoản",
    link: "#",
  },
  {
    id: 4,
    name: "Hỗ Trợ",
    link: "https://direct.lc.chat/13775445/",
  },
  {
    id: 5,
    name: "Link Dự Bị",
    link: "#",
  },
];

const mainFooterLinksList = [{ id: 1, title: 'SÒNG BÀI' }, { id: 2, title: 'THỂ THAO' }, { id: 3, title: 'XỔ SỐ' }, { id: 4, title: 'GAME BÀI' }, { id: 5, title: 'BÁN CÁ' }]

const Footer = () => {
  const [showFooterDetails, setShowFooterDetails] = useState(true);
  const footerDetailsRef = useRef(null);
  const ctx = useContext(UserContext);

  const referral = ctx.refCode && ctx.refCode
  const gameImgClick = () => {
    window.open(ctx.refCode ? `https://${referral}.sv368vn.today/register.html` : `https://www.sv368.bio/register`, '_blank')

  }

  const handleClick = (link) => {
    window.open(link);
  };
  const toggleFooterDetails = () => {
    setShowFooterDetails((prevState) => !prevState);
  };

  // Use the ref to add and remove the 'open' class
  const handleToggleAnimation = () => {
    if (footerDetailsRef.current) {
      footerDetailsRef.current.classList.toggle(styles.open);
    }
  };
  // console.log(showFooterDetails)
  return (
    <div className={styles.footerOverlay}>
      {/*
        <div className={styles.mainFooter}>
          <div className={styles.mainFooterLinks}>
            {mainFooterLinksList.map((link) => (
              <span
                key={link.name}
                className={
                  link.id === mainFooterLinksList.length ? styles.noBorder : ''
                }
                onClick={() => handleClick(link.link)}
              >
                {link.name}
              </span>
            ))}
          </div>
          <div className={styles.showDetails}>
            <span>Sơ Đồ Trang Mạng</span>
            <img
              src={showFooterDetails ? Up : Down}
              alt="arrows"
              onClick={() => {
                toggleFooterDetails();
                handleToggleAnimation();
              }}
            />
          </div>
        </div>
      */}

      {/* <FooterDetails
        showFooterDetails={showFooterDetails}
        ref={footerDetailsRef}
      /> */}

      <Footer1 mainFooterLinksList={mainFooterLinksList} gameImgClick={gameImgClick} />
      <div className={styles.copyrightWrapper}>Copyright © GA368. All Rights Reserved 2024.</div>
    </div>
  );
};

export default Footer;
