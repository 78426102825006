import styles from "./Header.module.css";
import SecondaryHeader from "./HeaderComponents/SecondaryHeader/SecondaryHeader";
import Header1 from "./HeaderComponents/Header1/Header1";
import { useState } from "react";

const Header = () => {
  const secondHeaderData = [
    { id: 1, title: "SÒNG BÀI" },
    { id: 2, title: "THỂ THAO" },
    { id: 3, title: "XỔ SỐ" },
    { id: 4, title: "GAME BÀI" },
    { id: 5, title: "BÁN CÁ" },
  ];

  const [loginPopoverOpen, setLoginPopoverOpen] = useState(false);

  return (
    <div className={styles.headerMain} style={{ position: "relative" }}>
      <Header1
        setLoginPopoverOpen={setLoginPopoverOpen}
        loginPopoverOpen={loginPopoverOpen}
      />
      <SecondaryHeader
        secondHeaderData={secondHeaderData}
        setLoginPopoverOpen={setLoginPopoverOpen}
      />
    </div>
  );
};

export default Header;
