import { Popover } from "@mui/material";
import styles from "./WalletModal.module.css";
import { IoMdClose } from "react-icons/io";
import { useContext, useEffect, useState } from "react";
import {
  AllGameWithdrawAPI,
  APIAllCategoryGames,
  getGameBalance,
  GetSingleGameBalance,
  getGameCategories,
  SingleGameWithdrawAPI,
} from "../../helpers/APIs/GameAPIs";
import { APIDagaBalance, APIDagaWithdraw } from '../../helpers/APIs/DagaAPI'
import { useMutation, useQuery, useQueryClient } from "react-query";
import UserContext from "../../helpers/Context/user-context";
import MyLoader from "../MyLoader";
import { SlRefresh } from "react-icons/sl";
import FirstCategorySection from '../../assets/FirstCategorySection.svg'
import OtherCategorySection from '../../assets/OtherCategorySection.svg'
import DagaIcon from '../../assets/DagaIcon.png'
import RefreshIcon from '../../assets/RefreshIcon.svg'
import { APIUser } from "../../helpers/APIs/UserAPIs";

const WalletModal = ({ open, id, anchorEl, handleClose }) => {
  const ctx = useContext(UserContext);
  const queryClient = useQueryClient();
  const [allTransferLoader, setAllTransferLoader] = useState(false);
  const [gamesWithdrawError, setGamesWithdrawError] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [dagaBalance, setDagaBalance] = useState(0);
  const [gameCategories, setGameCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDaga, setLoadingDaga] = useState(false);
  const [transferingPoints, setTransferingPoints] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState({
    status: false,
    id: null,
  });
  const [balance, setBalance] = useState(false);

  const useAllGameswithBalance = (showBalance) => {
    return useQuery(["all games with balance"], () => APIAllCategoryGames(), {
      enabled: showBalance, // Query will be enabled if `enabled` prop is true and `id` is truthy
    });
  };

  const { data: allCategoryGames, isLoading } =
    useAllGameswithBalance(showBalance);

  const allWithdrawMutation = useMutation(
    (array) => {
      return AllGameWithdrawAPI(array);
    },
    {
      onSuccess: (data, variables) => {
        // console.log(data)
        // console.log(data)
        const allGameBalanceData = Object.entries(data[0]);
        // console.log((allGameBalanceData))
        allGameBalanceData.map((item) => {
          // console.log(item)
          item.map((value) => {
            // console.log(value)
            setAllTransferLoader(false);
            if (value?.message === "PENDING_WITHDRAW") {
              setGamesWithdrawError("Sắp rút lui");
            } else if (value?.message === "PENDING_TRANSACTION") {
              setGamesWithdrawError(
                "Không thể chuyển điểm vào trò chơi vì đã có một yêu cầu chuyển điểm đang chờ xử lý."
              );
            } else if (value?.message === "BALANCE_NETWORK_ERROR") {
              setGamesWithdrawError("Lỗi mạng cân bằng");
            } else if (value?.message === "LACK_FUND") {
              setGamesWithdrawError("Thiếu vốn");
            } else if (value?.message === "NETWORK_ERROR") {
              setGamesWithdrawError("Lỗi mạng");
            } else if (value?.message === "ACCOUNT_DOESNT_EXIST") {
              setGamesWithdrawError("Tài khoản không tồn tại");
            } else if (value?.message === "POINTS_TRANSFERRED_SUCCESSFULLY") {
              setBalance(!balance);
              ctx.setUserInfo({
                ...ctx.userInfo,
                balance: data[1],
              });
              const cachedData = queryClient.getQueryData(
                "all games with balance"
              );
              // Assuming cachedData is an array of games with balance
              const updatedData = cachedData.map((game) => {
                const updatedGameItems = game.game_items.map((singleGame) => {
                  const matchingGame = variables.find(
                    (item) => item.game_id === singleGame.id
                  );
                  return {
                    ...singleGame,
                    balance: matchingGame ? 0 : singleGame.balance,
                  };
                });

                return {
                  ...game,
                  game_items: updatedGameItems,
                };
              });
              queryClient.setQueryData("all games with balance", updatedData);
              queryClient.invalidateQueries("all games with balance");
              setAllTransferLoader(false);
              setGamesWithdrawError();
            }
          });
        });

        // reload all points
        reloadAllPoints();
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      },
      onSettled: async () => {
      },
      onError: (error) => {
        console.error("API Error:", error);
        // setLoader(false);
        setAllTransferLoader(false);
      },
    }
  );
  // transfer points
  const onTransferPointClicked = async (game, balance, gameItem) => {
    setTransferingPoints(true)
    try {
      const response = await SingleGameWithdrawAPI(
        game.id,
        balance
      );

      if (response) {
        handleGameBalance(
          game?.game_id,
          game?.game_platform_id,
          gameItem?.id,
          game?.id
        )
        setTransferingPoints(false)
      }
    } catch (error) {
      setTransferingPoints(false)
      console.error("Error transfering points:", error);
    }
  };

  // onTransferEverythingClicked Withdraw
  const onTransferEverythingClicked = async () => {
    const finalBalanceArray = [];
    allCategoryGames?.map((item) => {
      item?.game_items &&
        item?.game_items?.length &&
        item?.game_items?.filter((game) => {
          if (game.balance != "LỖI MẠNG" && +game?.balance > 0) {
            finalBalanceArray.push({ game_id: game.id, amount: game.balance });
          }
          // console.log(finalBalanceArray)
        });
    });
    if (finalBalanceArray?.length) {
      setLoading(true)
      setAllTransferLoader(true);
      allWithdrawMutation.mutate(finalBalanceArray);
      // transfer points
      transferPointsToMain(dagaBalance)
      // }
    } else {
      setGamesWithdrawError("Không có gì để rút");
    }
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await getGameCategories();

      if (response) {
        setGameCategories(response?.games);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setGameCategories([]);
      console.error("Error fetching categories:", error);
    }
  };

  const mapBalance = (balance, item_id, id) => {
    const updatedCategories = gameCategories.map((category) => {
      if (category.id === item_id) {
        const updatedItems = category.game_items.map((game) => {
          if (game.id === id) {
            return { ...game, balance }; // Add or update the balance key
          }
          return game;
        });
        return { ...category, game_items: updatedItems };
      }
      return category;
    });
    // console.log(updatedCategories);
    setGameCategories(updatedCategories);
  };

  const handleGameBalance = async (game_id, game_platform_id, item_id, id) => {
    setBalanceLoading({
      status: true,
      id: id,
    });
    try {
      const response = await getGameBalance(game_id, game_platform_id);

      // console.log(response);
      setBalanceLoading({
        status: false,
        id: null,
      });

      if (response?.status && response?.status !== 'undefined') {
        mapBalance(response?.balance, item_id, id);
        if (response?.balance > 0) {
          setBalance(true)
          setShowBalance(true)
        }
      } else {
        mapBalance(0, item_id, id);
      }
      updateMainBalance()
    } catch (error) {
      setBalanceLoading({
        status: false,
        id: null,
      });
      console.error("Error fetching categories:", error);
    }
  };
  const handleWithdraw = async (finalBlanceArray) => {
    setBalanceLoading({
      status: true,
      id: null, // or set to some specific id if needed
    });

    try {
      const response = await GetSingleGameBalance(finalBlanceArray);

      // Reset loading state
      setBalanceLoading({
        status: false,
        id: null,
      });

      // Handle the response based on the status
      if (response) {
        if (response.message) {
          // Handle different messages from the API
          if (response.message === "PENDING_TRANSACTION") {
            console.log("Transaction is pending.");
          } else if (response.message === "BALANCE_NETWORK_ERROR") {
            console.error("Balance network error occurred.");
          } else if (response.message === "LACK_FUND") {
            console.warn("Insufficient funds for withdrawal.");
          } else if (response.message === "ACCOUNT_DOESNT_EXIST") {
            console.error("The account does not exist.");
          } else if (response.message === "PENDING_WITHDRAW") {
            console.log("Withdrawal is pending.");
          } else if (response.message === "NETWORK_ERROR") {
            console.error("A network error occurred.");
          }
        } else {
          // Successfully processed withdrawal
          console.log("Withdrawal successful:", response);
        }
      } else {
        console.warn("No response received from withdrawal API.");
      }
    } catch (error) {
      setBalanceLoading({
        status: false,
        id: null,
      });
      console.error("Error during withdrawal:", error);
    }
  };

  // transfer points to daga
  const transferPointsToMain = async (amount) => {
    setLoadingDaga(true)
    try {
      const res = await APIDagaWithdraw(amount);
      if (res) {
        setDagaBalance(0)
        handleDagaBalance()
        setLoadingDaga(false)
        updateMainBalance()
      }
    } catch (error) {
      setLoadingDaga(false)
      console.error("Error transfering points:", error);
    }
  };

  const reloadAllPoints = async () => {
    handleDagaBalance()
    console.log(gameCategories)
    for(var x in gameCategories){
      let item = gameCategories[x]
      if (item) {
        for(var gameItem in gameCategories[x].game_items) {
          let game = gameCategories[x].game_items[gameItem]
          handleGameBalance(
            game?.game_id,
            game?.game_platform_id,
            item?.id,
            game?.id
          )
        }
      }
    }
  };

  const handleDagaBalance = async () => {
    setLoadingDaga(true)
    try {
      const response = await APIDagaBalance();
      setLoadingDaga(false)

      if (response) {
        setDagaBalance(response.result)
        if (response.result > 0) {
          setBalance(true)
          setShowBalance(true)
        }
        return response?.result
      }
    } catch (error) {
      setLoadingDaga(false)
      console.error("Error fetching daga balance:", error);
    }
  };

  useEffect(() => {
    if (open) {
      getCategories();
      handleDagaBalance()
      setLoading(false);
    }
  }, [open]);

  const updateMainBalance = async () => {
    try {
      const updatedUserInfo = await APIUser();
      if (updatedUserInfo) {
        ctx.setUserInfo({
          ...updatedUserInfo,
          balance: Number(updatedUserInfo.balance),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: "14px",
      }}
    >
      <div className={styles.popoverStyles}>
        <div className={styles.loginHeader}>
          Tất cả các ví
          <IoMdClose
            onClick={() => handleClose()}
            style={{ cursor: "pointer" }}
            size={26}
            color="#00C1FE"
          />
        </div>

        <div className={styles.walletSection}>
          <div className={styles.dagaSection}>
            <hr className={styles.lineOne} />
            <img className={styles.dagaSectionImg} src={FirstCategorySection} alt="FirstCategorySection" />
            <hr className={styles.lineTwo} />
          </div>

          {/* DAGA Section */}
          <div className={styles.dagaBalanceWrapper}>
            <img style={{ width: '30px', height: '30px' }} src={DagaIcon} />
            <span style={{ paddingLeft: '16px' }}>ĐÁ GÀ</span>

            <div style={{ marginLeft: 'auto' }}>
              {loadingDaga ?
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <MyLoader />
                </div>
                :
                <>
                  <span className={styles.dagaBalance}>{dagaBalance} điểm</span>
                  <span
                    className={dagaBalance > 0 ? styles.dagaRefreshIcon : ''}
                  >
                    {dagaBalance > 0 ? <span onClick={() => transferPointsToMain(dagaBalance)}>Tải lại ví </span> : <></>}
                    <img onClick={() => handleDagaBalance()} style={{ cursor: 'pointer', paddingLeft: '4px' }} src={RefreshIcon} />
                  </span>
                </>
              }
            </div>
          </div>

          {/* Other Games Section */}
          <div className={styles.otherGameSection}>
            <hr className={styles.lineOne} />
            <img className={styles.silverWrapperImg} src={OtherCategorySection} alt="OtherCategorySection" />
            <hr className={styles.lineTwo} />
          </div>

          <div className={styles.gameSectionStyles}>
            {gameCategories && !loading ? (
              gameCategories?.map((item, index) => (
                  <div key={index} className={styles.gameSection}>
                    {item?.name ? JSON.parse(item?.name)?.en : `-`}
                    <div
                      className={styles.games}
                      style={{
                        backgroundColor:
                          item?.game_items?.length === 0 ? `transparent` : ``,
                      }}
                    >
                      {item?.game_items &&
                        item?.game_items?.map((game, i) => (
                          <div className={styles.singleGameSection} key={i}>
                            <div>
                              <img style={{ height: 30 }} src={game?.icon_square} />
                            </div>

                            {game?.balance !== undefined ? (
                              <>
                                {game.balance > 0 ?
                                  <div style={{ position: 'absolute', right: '12px' }}>
                                    <button
                                      onClick={() => {
                                        onTransferPointClicked(
                                          game,
                                          game.balance,
                                          item
                                        )
                                      }}
                                      style={{ cursor: 'pointer', background: 'none', border: 'none' }}
                                      disabled={transferingPoints}
                                    >
                                      {
                                        transferingPoints ?
                                          <MyLoader size={14} /> :
                                          // 'Chuyển khoản'
                                          <span>
                                            <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <rect width="20" height="20" rx="2" fill="url(#paint0_linear_3526_48869)" />
                                              <path d="M15.7435 11.2564H14.6666C14.5238 11.2564 14.3868 11.1997 14.2858 11.0987C14.1849 10.9977 14.1281 10.8608 14.1281 10.7179C14.1281 10.5751 14.1849 10.4382 14.2858 10.3372C14.3868 10.2362 14.5238 10.1795 14.6666 10.1795H15.7435C15.9814 10.1791 16.2095 10.0844 16.3777 9.91621C16.5459 9.74799 16.6406 9.51995 16.641 9.28205V4.97436C16.6406 4.73646 16.5459 4.50841 16.3777 4.3402C16.2095 4.17198 15.9814 4.0773 15.7435 4.07692H4.25634C4.01844 4.0773 3.7904 4.17198 3.62218 4.3402C3.45396 4.50841 3.35929 4.73646 3.35891 4.97436V9.28205C3.35929 9.51995 3.45396 9.74799 3.62218 9.91621C3.7904 10.0844 4.01844 10.1791 4.25634 10.1795H5.33326C5.47607 10.1795 5.61303 10.2362 5.71401 10.3372C5.815 10.4382 5.87173 10.5751 5.87173 10.7179C5.87173 10.8608 5.815 10.9977 5.71401 11.0987C5.61303 11.1997 5.47607 11.2564 5.33326 11.2564H4.25634C3.73283 11.256 3.23086 11.0479 2.86068 10.6777C2.4905 10.3075 2.28236 9.80557 2.28198 9.28205V4.97436C2.28236 4.45084 2.4905 3.94888 2.86068 3.5787C3.23086 3.20851 3.73283 3.00038 4.25634 3H15.7435C16.267 3.00038 16.769 3.20851 17.1392 3.5787C17.5094 3.94888 17.7175 4.45084 17.7179 4.97436V9.28205C17.7175 9.80557 17.5094 10.3075 17.1392 10.6777C16.769 11.0479 16.267 11.256 15.7435 11.2564Z" fill="white" />
                                              <path d="M13.2308 16.9998H6.76928C6.24576 16.9994 5.7438 16.7913 5.37362 16.4211C5.00344 16.0509 4.7953 15.5489 4.79492 15.0254V6.41004C4.79492 6.26723 4.85165 6.13027 4.95263 6.02929C5.05361 5.92831 5.19057 5.87158 5.33338 5.87158H14.6667C14.8095 5.87158 14.9465 5.92831 15.0475 6.02929C15.1484 6.13027 15.2052 6.26723 15.2052 6.41004V15.0254C15.2048 15.5489 14.9967 16.0509 14.6265 16.4211C14.2563 16.7913 13.7543 16.9994 13.2308 16.9998ZM5.87185 6.94851V15.0254C5.87222 15.2633 5.9669 15.4914 6.13512 15.6596C6.30334 15.8278 6.53138 15.9225 6.76928 15.9229H13.2308C13.4687 15.9225 13.6968 15.8278 13.865 15.6596C14.0332 15.4914 14.1279 15.2633 14.1283 15.0254V6.94851H5.87185Z" fill="white" />
                                              <path d="M9.99992 14.8462C9.9292 14.8464 9.85914 14.8325 9.79382 14.8054C9.7285 14.7783 9.66922 14.7385 9.61941 14.6883L7.46556 12.5344C7.37045 12.4324 7.31867 12.2974 7.32113 12.1579C7.32359 12.0184 7.3801 11.8853 7.47876 11.7866C7.57741 11.688 7.71051 11.6315 7.85001 11.629C7.98951 11.6265 8.12451 11.6783 8.22659 11.7734L9.99992 13.546L11.7733 11.7734C11.8753 11.6783 12.0103 11.6265 12.1498 11.629C12.2893 11.6315 12.4224 11.688 12.5211 11.7866C12.6197 11.8853 12.6763 12.0184 12.6787 12.1579C12.6812 12.2974 12.6294 12.4324 12.5343 12.5344L10.3804 14.6883C10.3306 14.7385 10.2713 14.7783 10.206 14.8054C10.1407 14.8325 10.0706 14.8464 9.99992 14.8462Z" fill="white" />
                                              <path d="M9.99989 14.8462C9.85708 14.8462 9.72012 14.7895 9.61914 14.6885C9.51816 14.5875 9.46143 14.4506 9.46143 14.3078V9.28211C9.46143 9.1393 9.51816 9.00235 9.61914 8.90136C9.72012 8.80038 9.85708 8.74365 9.99989 8.74365C10.1427 8.74365 10.2797 8.80038 10.3806 8.90136C10.4816 9.00235 10.5383 9.1393 10.5383 9.28211V14.3078C10.5383 14.4506 10.4816 14.5875 10.3806 14.6885C10.2797 14.7895 10.1427 14.8462 9.99989 14.8462Z" fill="white" />
                                              <path d="M15.3846 6.94851H4.61537C4.47256 6.94851 4.3356 6.89177 4.23462 6.79079C4.13363 6.68981 4.0769 6.55285 4.0769 6.41004C4.0769 6.26723 4.13363 6.13027 4.23462 6.02929C4.3356 5.92831 4.47256 5.87158 4.61537 5.87158H15.3846C15.5274 5.87158 15.6644 5.92831 15.7653 6.02929C15.8663 6.13027 15.9231 6.26723 15.9231 6.41004C15.9231 6.55285 15.8663 6.68981 15.7653 6.79079C15.6644 6.89177 15.5274 6.94851 15.3846 6.94851Z" fill="white" />
                                              <defs>
                                                <linearGradient id="paint0_linear_3526_48869" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
                                                  <stop stop-color="#06BBF7" />
                                                  <stop offset="1" stop-color="#17346C" />
                                                </linearGradient>
                                              </defs>
                                            </svg>
                                          </span>
                                      }
                                  </button>
                                  </div>
                            : <></>
                                }
                            <div
                              style={{
                                color: "#00C1FE",
                              }}
                            >
                              {game?.balance}
                            </div>
                          </>
                        ) : balanceLoading?.id === game?.id &&
                      balanceLoading?.status ? (
                      <MyLoader size={14} />
                      ) : (
                      <span className={styles.otherGameBalances}>{game?.balance ? game?.balance : 0}<img
                        style={{ cursor: 'pointer', paddingLeft: '8px', height: '14px' }}
                        src={RefreshIcon}
                        onClick={() => {
                          handleGameBalance(
                            game?.game_id,
                            game?.game_platform_id,
                            item?.id,
                            game?.id
                          )
                        }}
                      /></span>
                            )}
                    </div>
                        ))}
                  </div>
                  </div>
          ))
          ) : loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <MyLoader />
          </div>
          ) : (
          <></>
            )}
        </div>
        <button
          onClick={onTransferEverythingClicked}
          disabled={!balance || allTransferLoader}
          className={styles.buttonStyles}
        >
          {allTransferLoader ? <MyLoader /> : 'Chuyển mọi thứ vào tài khoản chính'}
        </button>
      </div>
    </div>
    </Popover >
  );
};

export default WalletModal;
