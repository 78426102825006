import style from "../../components/HottestSection/HottestSection.module.css";
import DividerLeft from "../../assets/HomeImages/DividerLeft.webp";
import DividerRight from "../../assets/HomeImages/DividerRight.webp";
import Game1 from "../../assets/HomeImages/Section1/Casino.webp";
import Game2 from "../../assets/HomeImages/Section1/Fish.webp";
import SvenusImg from "../../assets/HomeImages/Section1/svenus-img.webp";
// import DagaTitle from "../../assets/HomeImages/Section1/DagaTitle.webp";
import { useContext, useState, useEffect } from "react";
import UserContext from "../../helpers/Context/user-context";
import { useNavigate } from "react-router-dom";
import DagaErrorModal from "../HomeComponents/DagaErrorModal";
import { useDagaGame } from "../../helpers/useDagaGame";

import { useQuery } from "react-query";
import SingleGameList from "./SingleGameList/SingleGameList";
import { getGameCategories } from "../../helpers/APIs/GameAPIs";

export const HottestSection = () => {
  const { transferAllPointsInDaga, showErrorModal, setShowErrorModal } = useDagaGame();

  const [gameCategories, setGameCategories] = useState();
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState(null);
  const [gameLink, setGameLink] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [activeCategoryId, setActiveCategoryId] = useState("");

  const ctx = useContext(UserContext);
  const navigate = useNavigate();

  const referral = ctx.refCode && ctx.refCode

  const { data, isLoading, error } = useQuery('categories', getGameCategories);

  useEffect(() => {
    if (data) {
      setGameCategories(data);
      setLoading(isLoading);
      console.log('data:: ', data)
    }
    if (isLoading) setLoading(isLoading);
    if (error) console.error('Error fetching data: ', error)
  }, [data, isLoading, error]);

  useEffect(() => {
    if (gameCategories?.games?.length > 0) {
      setActiveCategoryId(gameCategories?.games[0]?.id);
      // console.log(gameCategories)
    } else if (gameCategories?.games?.length == 0) {
      setErrorMsg("Không tìm thấy trò chơi nào");
    } else if (gameCategories == "NETWORK_ERROR") {
      setErrorMsg("Lỗi mạng");
    }
  }, [gameCategories]);

  useEffect(() => {
    if (activeCategoryId) {
      setGames(
        gameCategories?.games?.filter((category) => {
          return Number(category.id) === Number(activeCategoryId);
        })[0].game_items
      );
    }
  }, [activeCategoryId]);

  const gameImgClick = () => {
    window.open(ctx.refCode ? `https://${referral}.sv368vn.today/register.html` : `https://www.sv368.bio/register`, '_blank')

  }
  return (
    <div className={style.wrapper}>
      <div className={style.sectionTitle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
          <path d="M319.887 3.00002L317 0.113264L314.113 3.00002L317 5.88677L319.887 3.00002ZM317 2.50002L0.5 2.49999L0.5 3.49999L317 3.50002L317 2.50002Z" fill="white" />
        </svg>

        <div className={style.title}>Hot Nhất</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
          <path d="M0.113251 2.99999L3 5.88674L5.88675 2.99999L3 0.113236L0.113251 2.99999ZM319.5 2.50002L3 2.49999L3 3.49999L319.5 3.50002L319.5 2.50002Z" fill="white" />
        </svg>
      </div>
      {/* <div className={style.sectionContent}>
        <div className={style.imageContainer}>
          <img className={style.dagaTitleImage} src={SvenusImg} alt="SvenusImg" />
          <button className={style.overlayButton}>Chơi ngay</button>
        </div>
      </div> */}
      {/* <div className={style.leftContent}>
          <div className={style.dagaSection}>
            <img
              className={style.dagaTitleImage}
              src={DagaTitle}
              alt={`DagaTitle`}
            />
            <span className={style.dagaDescription}>
              Lorem ipsum dolor sit amet, conset sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore
            </span>
            <button className={style.dagaButton} style={{ cursor: 'pointer' }} onClick={() => loading || transferAllPointsInDaga()}
>
              <span className={style.dagaButtonText}>Chơi Ngay</span>
            </button>
          </div>
        </div>
        <div className={style.rightContent}>
          <img className={style.hottestGameImage} src={Game1} alt={`Game1`} onClick={gameImgClick} />
          <img className={style.hottestGameImage} src={Game2} alt={`Game2`} onClick={gameImgClick} />
        </div> */}
      {showErrorModal && (
        <DagaErrorModal
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
        />
      )}

      {activeCategoryId ? (
        <SingleGameList
          games={games}
          setGames={setGames}
          activeCategoryId={activeCategoryId}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default HottestSection;
