import { useNavigate } from "react-router-dom";
import styles from "./LoginButtons.module.css";
import { useEffect, useRef, useState } from "react";
import RegistrationModal from "../../../RegistrationModal/RegistrationModal";
import LoginModal from "../../../LoginModal/LoginModal";

const LoginButtons = ({ loginPopoverOpen, setLoginPopoverOpen }) => {
  const navigate = useNavigate();
  const [registrationModal, setRegistrationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const loginButtonRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(loginButtonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLoginPopoverOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (loginPopoverOpen) {
      handleClick();
    }
  }, [loginPopoverOpen]);

  return (
    <div className={styles.loginButtonsOverlay}>
      <button ref={loginButtonRef} aria-describedby={id} onClick={handleClick}>
        Đăng Nhập
      </button>
      <button onClick={() => setRegistrationModal(true)}>Đăng Ký</button>

      {registrationModal && (
        <RegistrationModal
          registrationModal={registrationModal}
          setRegistrationModal={setRegistrationModal}
        />
      )}

      <LoginModal
        open={open}
        id={id}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </div>
  );
};

export default LoginButtons;
