import React from "react";
import triangle from "../../assets/triangle.webp";
import { AiOutlineCloseCircle } from "react-icons/ai";
import styles from "./InputTooltipV2.module.css";
import CommaSeperator from "../CommaSeperator";
import CurrencyIcon from '../../assets/CurrencyIcon.svg'
// import { useContext } from 'react';
// import UserContext from '../../helpers/Context/user-context';

const InputTooltipV2 = ({
  title,
  amount,
  setAmount,
  onAmountChange,
  withdraw,
  withdrawMinAmount,
  withdrawMaxAmount,
  disabled = false,
}) => {
  let tooltipMinCheck = withdraw ? withdrawMinAmount : 150;
  let tooltipMaxCheck = withdraw ? withdrawMaxAmount : 90000;

  return (
    <div className={styles.depositInputWrapper}>
      <div className={styles.requiredLabel}>
        {title}
        <span> * </span>
      </div>
      <div className={styles.depositInputContainer}>
        <div className={styles.inputWrapper}>
          <span style={{ color: '#00F7F1', fontWeight: '700', position: 'relative', marginRight: '8px' }}>
            <img style={{ position: 'absolute' }} src={CurrencyIcon} alt="CurrencyIcon" />
          </span>
          <input
            value={amount}
            type="number"
            placeholder={`${CommaSeperator(tooltipMinCheck)}K ~ ${CommaSeperator(tooltipMaxCheck)}K`}
            onChange={(e) => onAmountChange(e.target.value)}
            disabled={disabled}
          />

          {/* <div className={styles.tooltipWrapper}>
            {(amount && amount < tooltipMinCheck) ||
            amount > tooltipMaxCheck ? (
              <>
                <div className={styles.tooltip}>
                  <span>
                    {CommaSeperator(tooltipMinCheck)} ~{" "}
                    {CommaSeperator(tooltipMaxCheck)}
                  </span>
                  <div className={styles.arrowDown}>
                    <img src={triangle} alt="arrow" />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div> */}
          {amount && (
            <AiOutlineCloseCircle
              onClick={() => {
                setAmount("");
              }}
              className={styles.cancelIcon}
            />
          )}
        </div>
      </div>

      <span className={styles.inputAmoutRequired}>Vui lòng nhập số tiền</span>
    </div>
  );
};

export default InputTooltipV2;
