import { Modal } from "@mui/material";
import styles from "./RegistrationModal.module.css";
import Register from "../../pages/Register/Register";

const RegistrationModal = ({ registrationModal, setRegistrationModal }) => {
  return (
    <Modal open={registrationModal}>
      <div
        className={styles.modalOverlay}
        onClick={() => setRegistrationModal(false)}
      >
        <div
          className={styles.logoutOverlay}
          onClick={(e) => e.stopPropagation()}
        >
          <Register setRegistrationModal={setRegistrationModal} />
        </div>
      </div>
    </Modal>
  );
};

export default RegistrationModal;
