import React from "react";

export default function AllWalletIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_567_8564)">
        <mask
          id="mask0_567_8564"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="17"
        >
          <path d="M16 0.5H0V16.5H16V0.5Z" fill={color} />
        </mask>
        <g mask="url(#mask0_567_8564)">
          <path
            d="M10.9531 12.8436V11.156H10.111V10.3139H10.9531V8.62462H15.1998V6.09325H1.64191C1.34535 6.09132 1.05467 6.01036 0.799805 5.85873L0.799805 14.1088C0.800139 14.4444 0.933595 14.7661 1.17088 15.0034C1.40817 15.2407 1.72991 15.3742 2.06549 15.3745H15.1998V12.8436H10.9531Z"
            fill={color}
          />
          <path
            d="M15.1998 9.46631H11.7969V10.3084H12.639V11.1505H11.7969V11.9926H15.1998V9.46631Z"
            fill={color}
          />
          <path
            d="M8.39602 2.94041L7.15559 1.69999L5.91602 2.93957C5.97181 3.22744 6.12602 3.48693 6.3522 3.67355C6.57838 3.86017 6.86244 3.96228 7.15567 3.96238C7.44889 3.96248 7.73302 3.86056 7.95933 3.6741C8.18564 3.48763 8.34003 3.22824 8.39602 2.94041Z"
            fill={color}
          />
          <path
            d="M9.05865 3.60231C8.89235 3.96756 8.62447 4.27726 8.28697 4.49443C7.94947 4.7116 7.55662 4.82707 7.15529 4.82707C6.75395 4.82707 6.3611 4.7116 6.0236 4.49443C5.6861 4.27726 5.41822 3.96756 5.25192 3.60231L3.60645 5.24778H10.7058L9.05865 3.60231Z"
            fill={color}
          />
          <path
            d="M2.41301 5.24989L4.10059 3.56231H1.64164C1.41785 3.56231 1.20323 3.65121 1.04499 3.80945C0.886751 3.96769 0.797852 4.18231 0.797852 4.4061C0.797852 4.62988 0.886751 4.8445 1.04499 5.00275C1.20323 5.16099 1.41785 5.24989 1.64164 5.24989H2.41301Z"
            fill={color}
          />
          <path
            d="M12.2181 3.56231H10.2109L11.8985 5.24989H13.4838V4.82883C13.4839 4.66255 13.4512 4.49787 13.3877 4.34422C13.3241 4.19056 13.2309 4.05094 13.1134 3.93332C12.9958 3.8157 12.8563 3.72239 12.7026 3.65873C12.549 3.59507 12.3844 3.56231 12.2181 3.56231Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_567_8564">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
