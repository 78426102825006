import React from "react";

export default function NotificationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="32"
      viewBox="0 0 44 32"
      fill="none"
    >
      <path
        d="M0.666992 5.33333C0.666992 3.91885 1.2289 2.56229 2.22909 1.5621C3.22928 0.561903 4.58584 0 6.00033 0H38.0003C39.4148 0 40.7714 0.561903 41.7716 1.5621C42.7718 2.56229 43.3337 3.91885 43.3337 5.33333V26.6667C43.3337 28.0812 42.7718 29.4377 41.7716 30.4379C40.7714 31.4381 39.4148 32 38.0003 32H6.00033C4.58584 32 3.22928 31.4381 2.22909 30.4379C1.2289 29.4377 0.666992 28.0812 0.666992 26.6667V5.33333ZM6.00033 2.66667C5.29308 2.66667 4.6148 2.94762 4.11471 3.44772C3.61461 3.94781 3.33366 4.62609 3.33366 5.33333V5.912L22.0003 17.112L40.667 5.912V5.33333C40.667 4.62609 40.386 3.94781 39.8859 3.44772C39.3858 2.94762 38.7076 2.66667 38.0003 2.66667H6.00033ZM40.667 9.02133L28.1123 16.5547L40.667 24.28V9.02133ZM40.5763 27.3573L25.5363 18.1013L22.0003 20.2213L18.4643 18.1013L3.42433 27.3547C3.57586 27.9221 3.9105 28.4237 4.3763 28.7815C4.84209 29.1393 5.41298 29.3332 6.00033 29.3333H38.0003C38.5873 29.3334 39.1579 29.1398 39.6237 28.7825C40.0894 28.4253 40.4243 27.9243 40.5763 27.3573ZM3.33366 24.28L15.8883 16.5547L3.33366 9.02133V24.28Z"
        fill="#737797"
      />
    </svg>
  );
}
