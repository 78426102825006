import React, { useEffect } from "react";
import captchas from "../../data/captchas";
import styles from "./CaptchaInput.module.css";
import { MdVerified } from "react-icons/md";

const CaptchaInput = ({
  captcha,
  setCaptcha,
  userCaptchaInput,
  setUserCaptchaInput,
}) => {
  useEffect(() => {
    setCaptcha(captchas[Math.floor(Math.random() * captchas.length)]);
  }, []);

  return (
    <div>
      <div className={styles.requiredLabel}>
        Mã xác nhận<span>*</span>
      </div>
      <div className={styles.captchaInput}>
        <input
          type="text"
          placeholder="Mã xác nhận"
          name="captcha"
          value={userCaptchaInput}
          required
          onChange={(e) => setUserCaptchaInput(e.currentTarget.value)}
        />
        <img src={captcha ? captcha.image : ""} width="100px" alt="captcha" />

        <div className={styles.leftIconStyles}>
          <MdVerified size={24} color="#00C1FE" />
        </div>
      </div>
    </div>
  );
};

export default CaptchaInput;
