import { useNavigate } from "react-router-dom";
import styles from "./TransactionButtons.module.css";
import DepositIcon from "../../../../assets/TransactionIcon/Deposit.png";
import WithdrawIcon from "../../../../assets/TransactionIcon/Withdrawal.png";

const TransactionButtons = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.loginButtonsOverlay}>
      <button onClick={() => navigate("/profile/deposit")}>
        <img src={DepositIcon} />
        Nạp tiền
      </button>
      <button onClick={() => navigate("/profile/withdraw")}>
        <img src={WithdrawIcon} />
        Rút Tiền
      </button>
    </div>
  );
};

export default TransactionButtons;
