import getAxiosInstance from "./BaseUrl";

// phone check API
export const APICheckIfPhoneExists = async (phone) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get("/check_phone/" + phone);
    if (res?.data) {
      return res.data;
    }
  } catch (e) {
    // console.log(e);
  }
};

// register API
export const APIRegisterUser = async (phone, password, agentId, token, webGlResult) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.post("/register_user", { phone, password, 
      agent_id: agentId,
      //  captcha_token: token, 
       fp_data: webGlResult });
    if (res.data && res.data.status && res.data.token) {
      return res.data.token;
    }
  } catch (e) {
    if (e.response.data?.message === "REGISTRATION_FORBIDDEN") {
      return e.response.data.message;
    } else if (e.response.data?.message === "CAPTCHA_FAILED") {
      return e.response.data.message;
    }
  }
  return null;
};

// login User
export const APILoginUser = async (phone, password, token) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.post("/login_user", {
      phone,
      password,
      // captcha_token: token,
    });
    if (res.status === 200) {
      if (res.data.message === "LOGIN_SUCCESS") {
        return res.data;
      } else if (res.data.message === "REQUIRE_RESET_PASSWORD") {
        return res.data;
      } else {
        return res.data
      }

    }
  } catch (e) {
    if (e.response.data?.message === "PASSWORD_INCORRECT") {
      return e.response.data.message;
    } else if (e.response.data?.message === "PLAYER_NOT_ALLOWED_LOGIN") {
      return e.response.data.message;
    } else if (e.response.data?.message === "TOO_MANY_ATTEMPTS") {
      return e.response.data.message;
    } else if (e.response.data?.message === "REQUIRE_RESET_PASSWORD") {
      return e.response.data.message;
    } else if (e.response.data?.message === "CAPTCHA_FAILED") {
      return e.response.data.message;
    } else if (e.status === 422) {
      return null
    }
  }
};

// get user data
export const APIUser = async () => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get("/user", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    // console.log("res is", res);
    if (res.status === 200) {
      // console.log("res.data is", res.data);
      return res.data;
    }
  } catch (e) {
    return e;
  }
  return null;
};

// register user as agent
export const APIRegisterAgent = async (name, phone, password) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.post("/register_agent", {
      name,
      phone,
      password,
    });
    if (res.data && res.data.status) {
      return res.data.status;
    }
  } catch (e) {
    // console.log(e);
  }
  // return null;
};

export const refrealApi = async (agentId) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get(`/get_agent_code/${agentId}`);
    // console.log("res is ", res);
    if (res.status === 200) {
      return res.data.reference_agent_id;
    }
  } catch (e) {
    // console.log(e);
  }
};
// Reset Password 
export const APIResetPassword = async (oldPassword, newPassword) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.post("/player/password/reset", {
      password: oldPassword,
      new_password: newPassword
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res?.data) {
      return res.data;
    }
  } catch (e) {
    console.log('e is', e)
    if (e.response.data?.message === "PASSWORD_INCORRECT") {
      return e.response.data.message;
    } else {
      return null
    }
  }
};