import CryptoJS from "crypto-js";
import getAxiosInstance from "./BaseUrl";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_SECRET = process.env.REACT_APP_API_SECRET;
const ZALO_APP_ID = process.env.REACT_APP_ZALO_APP_ID;
const SMS_ID = process.env.REACT_APP_SMS_ID;

export const requestOTP = async (phone, otp, token) => {
  console.log('OTP: ', otp);
  const timestamp = Math.floor(Date.now() / 1000);

  // Function to encrypt OTP
  const encryptOTP = () => {
    return CryptoJS.AES.encrypt(otp.toString(), SECRET_KEY).toString();
  };

  const encryptedOTP = encryptOTP();

  // Generate MD5 hash signature
  const sign = CryptoJS.MD5(API_KEY + API_SECRET + timestamp).toString();

  const url = 'https://api.laaffic.com/v3/sendSms';
  const body = {
    'appId': SMS_ID,
    'numbers': phone,
    'content': `Your OTP code is ${otp}`, // Customize the OTP message
  };

  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Sign': sign,
    'Timestamp': timestamp,
    'Api-Key': API_KEY,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error sending OTP:', error);
  }
};

// Generate OTP: /generate-otp
export const generateOTP = async (phone) => {
  const BaseURL = await getAxiosInstance();

  var formData = new FormData();
  formData.append('phone', phone)

  try {
    const response = await BaseURL.post('/generate-otp', formData, {})

    if (response.data) {
      console.log(`Generate OTP Response: ${response}`)
      return response.data
    }
  } catch (error) {
    console.error(`Generate OTP ERROR: ${error}`)
  }
  return null;
}

// Verify OTP: /verify-otp
export const verifyOTP = async (phone, otp) => {
  const BaseURL = await getAxiosInstance();

  var formData = new FormData();
  formData.append('phone', phone);
  formData.append('otp', otp);

  try {
    const response = await BaseURL.post('/verify-otp', formData, {});

    if (response.data) {
      console.log(`Verify OTP Response: ${response}`);
      return response.data
    }
  } catch (error) {
    console.log(`Verify OTP ERROR: ${error}`)
  }
  return null;
}

// Forgot Password: /forgot-password
export const forgotPassword = async (token, phone, password) => {
  const BaseURL = await getAxiosInstance();

  var formData = new FormData();
  formData.append('token', token);
  formData.append('phone', phone);
  formData.append('password', password);

  try {
    const response = await BaseURL.post('/forgot-password', formData, {});

    if (response.data) {
      console.log(`Forgot Password Response: ${response}`)
      return response.data
    }
  } catch (error) {
    console.log(`Forgot Password ERROR: ${error}`)
  }
  return null;
}
