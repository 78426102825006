import styles from "../Header1/Header1.module.css";
import SupportIcon from "../../../../assets/NewHomeIcons/Header1Icons/Support Icon.webp";
import PromotionIcon from "../../../../assets/NewHomeIcons/Header1Icons/Promotion icon.webp";
import MessengerIcon from "../../../../assets/NewHomeIcons/Header1Icons/Messenger Icon.webp";
import Withdraw from "../../../../assets/NewHomeIcons/Header1Icons/Withdraw.webp";
import Deposit from "../../../../assets/NewHomeIcons/Header1Icons/Deposit.webp";
import Profile from "../../../../assets/NewHomeIcons/Header1Icons/Profile.webp";
import SiteLogo from "../../../../assets/NewHomeIcons/Header2Icons/Ga368 Logo.webp";
import { useNavigate } from "react-router-dom";
import LoginButtons from "../LoginButtons/LoginButtons";
import LogoutConfirmationModal from "../../../LogoutConfirmationModal/LogoutConfirmationModal";
import { useContext, useEffect, useState } from "react";
import LogoutImg from "../../../../assets/NewHomeIcons/Header2Icons/Logout.webp";
import UserContext from "../../../../helpers/Context/user-context";
import { useDagaGame } from "../../../../helpers/useDagaGame";
import DagaIcon from "../../../../assets/NewHomeIcons/Header2Icons/DagaIcon.webp";
import CommaSeperator from "../../../CommaSeperator";
import { APIDagaWithdraw } from "../../../../helpers/APIs/DagaAPI";
import MyLoader from "../../../MyLoader";
import Balance from "../../../../assets/BalanceIcon/Balance.webp";
import TransactionButtons from "../TransactionButtons/TransactionButtons";
import { Avatar } from "@mui/material";
import WalletModal from "../../../WalletModal/WalletModal";

// const linkArray = [
//   { name: "Nạp tiền", link: "/profile/deposit", image: Withdraw },
//   { name: "Rút tiền", link: "/profile/withdraw", image: Deposit },
//   { name: "Khuyến mãi", link: "/promotions", image: PromotionIcon },
//   {
//     name: "Hỗ Trợ 24/7",
//     link: "https://direct.lc.chat/13775445/",
//     image: SupportIcon,
//   },
//   { name: "Tài Khoản", link: "/profile", image: Profile },
// ];
export const Header1 = ({ loginPopoverOpen, setLoginPopoverOpen }) => {
  const navigate = useNavigate();
  const {
    transferAllPointsInDaga,
    getDagaBalance,
    loading,
    dagaLoader,
    setDagaLoader,
    showErrorModal,
    setShowErrorModal,
  } = useDagaGame();
  const token = localStorage.getItem("auth_token");
  const ctx = useContext(UserContext);
  const [logoutModal, setLogoutModal] = useState(false);
  const [gameTransactionError, setGameTransactionError] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLoginPopoverOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dagaWithdraw = async () => {
    if (ctx.dagaBalance < 1) {
      setGameTransactionError("Số dư tối thiểu phải là 1");
    } else {
      setDagaLoader(true);
      const dagaPoints = Math.floor(ctx.dagaBalance);
      const res = await APIDagaWithdraw(dagaPoints);
      if (res === "FORBIDDEN") {
        setGameTransactionError("CẤM");
      } else if (res === "LACK_FUND") {
        setGameTransactionError("Thiếu vốn");
      } else if (res === "NETWORK_ERROR") {
        setGameTransactionError("LỖI MẠNG");
      } else if (res === "WITHDRAW_LIMIT_OVER") {
        setGameTransactionError(
          "Tính năng rút tiền tạm đóng vì bạn đã có 1 giao dịch rút tiền hôm nay. Vui lòng thực hiện lại sau 00:00 hoặc liên hệ chăm sóc khách hàng"
        );
      } else if (res?.status) {
        ctx.setDagaBalance(ctx.dagaBalance - dagaPoints);
        ctx.setUserInfo({
          ...ctx.userInfo,
          balance: res.player_balance,
        });
      }
      setDagaLoader(false);
    }
  };

  useEffect(() => {
    if (token) {
      getDagaBalance();
    }
    // }
  }, [token]);

  return (
    <div className={styles.header1}>
      <div className={styles.siteLogo} onClick={() => navigate("/")}>
        <img className={styles.siteLogoImage} src={SiteLogo} alt={"SiteLogo"} />
      </div>

      <div className={styles.loginButton}>
        {/* {token ? (
          !dagaLoader ? (
            <div className={styles.transferSection}>
              <img className={styles.iconSize2} src={DagaIcon} alt="Balance" />
              <span
                className={`${
                  ctx.dagaBalance === "FORBIDDEN" ||
                  ctx.dagaBalance === "NETWORK_ERROR"
                    ? styles.balanceError
                    : styles.balance
                }`}
              >
                {ctx.dagaBalance === "NETWORK_ERROR"
                  ? "LỖI MẠNG"
                  : ctx.dagaBalance === "FORBIDDEN"
                  ? "CẤM"
                  : // : CommaSeperator(+ctx.dagaBalance)
                  ctx.dagaBalance > 0
                  ? CommaSeperator(ctx.dagaBalance)
                  : ctx.dagaBalance}
              </span>

              <div>
                {ctx.dagaBalance > 0 && (
                  <button
                    className={styles.transferButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      dagaWithdraw();
                    }}
                  >
                    Chuyển ví
                  </button>
                )}
              </div>
            </div>
          ) : (
            <MyLoader size={16} />
          )
        ) : (
          <></>
        )} */}

        {ctx?.userInfo ? (
          <div className={styles.accountStyles}>
            <Avatar
              className={styles.avatarStyles}
              src={ctx.userInfo?.image ?? `-`}
            />
            <span>{ctx.userInfo?.phone ?? `-`}</span>
          </div>
        ) : (
          <></>
        )}

        {Number(ctx?.userInfo?.balance) >= 0 && (
          <div
            className={styles.balanceSection}
            aria-describedby={id}
            onClick={handleClick}
          >
            <img className={styles.iconSize2} src={Balance} alt="Balance" />
            <span className={styles.balanceStyles}>Tổng điểm :</span>
            <span className={styles.balanceAmountStyles}>
              {ctx.userInfo?.balance &&
                `${CommaSeperator(Number(ctx.userInfo?.balance).toFixed(1))}`}
            </span>
          </div>
        )}

        {token ? (
          // <div
          //   className={styles.logoutBtn}
          //   onClick={() => setLogoutModal(true)}
          // >
          //   <img className={styles.iconSize} src={LogoutImg} alt="logoutIcon" />
          //   Đăng xuất
          // </div>
          <TransactionButtons />
        ) : (
          <LoginButtons
            setLoginPopoverOpen={setLoginPopoverOpen}
            loginPopoverOpen={loginPopoverOpen}
          />
        )}
      </div>

      {logoutModal && (
        <LogoutConfirmationModal
          logoutModal={logoutModal}
          closeLogoutModal={() => setLogoutModal(false)}
        />
      )}

      <WalletModal
        open={open}
        id={id}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />

      {/* <div className={styles.linkWrapper}>
        {linkArray.map((item) => (
          <span
            key={item.name}
            className={styles.header1_links}
            onClick={() => {
              if (item.name === "Hỗ Trợ 24/7") {
                window.open("https://direct.lc.chat/13775445/", "_blank");
              } else {
                navigate(item.link);
              }
            }}
          >
            <img
              className={styles.header1_icons}
              src={item.image}
              alt={item.name}
            />
            {item.name}
          </span>
        ))}
      </div> */}
    </div>
  );
};

export default Header1;
