import { useContext, useEffect, useState } from "react";
import styles from "./PlayGameModal.module.css";
import { Modal, Box } from "@mui/material";
import UserContext from "../../../helpers/Context/user-context";
import CommaSeperator from "../../CommaSeperator";
import JsonFormate from "../../JsonFormate";
import MyLoader from "../../MyLoader";
import Backdrop from "@mui/material/Backdrop";
import dbIcon from "../../../assets/HeaderIcons/Balance.webp";
import Refresh from "../../../assets/Refresh.webp";
import { useNavigate } from "react-router-dom";
import PointsConverter from "../../PointsConverter/PointsConverter";
import { APIGameBalance, APIGameDeposit, SingleGameWithdrawAPI } from "../../../helpers/APIs/GameAPIs";
import {
  APIDagaBalance,
  APIDagaDeposit,
  APIDagaPoints,
  APIDagaWithdraw,
} from "../../../helpers/APIs/DagaAPI";
import MaxMinLimit from "../../MaxMinLimit/MaxMinLimit";
import PendingTransactionModal from "./PendingTransaction/PendingTransactionModal";
import useLogout from "../../../helpers/useLogout";

const PlayGameModal = ({
  playGameState,
  setPlayGameState,
  singleGameModal,
  setSingleGameModal,
  daga,
  setShowGames,
  setDaga,
}) => {
  const [transferPoints, setTransferPoints] = useState("");
  const [gameBalance, setGameBalance] = useState();
  const [error, setError] = useState();
  const [loader, setLoader] = useState(false);
  const [balanceloader, setbalanceLoader] = useState(false);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  const [convertedPointsRatio, setConvertedPointsRatio] = useState();
  const [buttonEnabled, setButtonEnabled] = useState(); // Store the result here
  const [pendingModal, setPendingModal] = useState();
  const navigate = useNavigate();
  const ctx = useContext(UserContext);

  // category game API call
  useEffect(() => {
    if (singleGameModal.game_id === 'daga') {
      if (ctx.dagaBalance === 0 || ctx.dagaBalance > 0) {
        // set daga balance if its in context
        setGameBalance(ctx.dagaBalance);
      } else {
        // get DAGA balance if context is empty
        gameBalanceApi();
      }
      // get DAGA points API call
      dagaPointsApi();
    } else {
      // get normal game balance, set max, min and convertion points
      gameBalanceApi();
      setMinValue(ctx.categoryGamesContext?.rates[singleGameModal?.game_id].min_amount);
      setMaxValue(ctx.categoryGamesContext?.rates[singleGameModal?.game_id].max_amount);
      checkRates(ctx.categoryGamesContext?.rates[singleGameModal?.game_id]);
    }
  }, []);

  const logout = useLogout();
  // console.log(ctx.gamesRates[singleGameModal?.game_id])
  // console.log(singleGameModal)

  // game Balance API
  const gameBalanceApi = async () => {
    if (singleGameModal.game_id === 'daga') {
      setbalanceLoader(true);
      const dagaBal = await APIDagaBalance();
      if (dagaBal == "Unauthenticated") {
        logout();
      } else {
        setGameBalance(
          dagaBal == "FORBIDDEN"
            ? "CẤM"
            : dagaBal == "NETWORK_ERROR"
            ? "LỖI MẠNG"
            : dagaBal.result
        );
        setbalanceLoader(false);
        ctx.setDagaBalance(dagaBal.result);

        // console.log(dagaBal == "NETWORK_ERROR")
      }
    } else {
      setbalanceLoader(true);
      // console.log(singleGameModal.id)
      const gameBal = await APIGameBalance(singleGameModal.id);
      if (gameBal == 'Unauthenticated.') {
        logout();
      } else {
        setGameBalance(
          gameBal == "ACCOUNT_DOESNT_EXIST"
            ? 0
            : gameBal == "NETWORK_ERROR"
            ? "LỖI MẠNG"
            : gameBal == "NOT_SUPPORTED"
            ? "KHÔNG ĐƯỢC HỖ TRỢ"
            : gameBal == null
            ? 0
            : gameBal == undefined
            ? 0
            : gameBal.result
        );
      }
      setbalanceLoader(false);
      // console.log(gameBal);
    }
  };

  // get DAGA Points API
  const dagaPointsApi = async () => {
    const dagaPointsRes = await APIDagaPoints();
    // console.log(dagaPointsRes);
    setMinValue(dagaPointsRes?.daga_rate?.min_amount);
    setMaxValue(dagaPointsRes?.daga_rate?.max_amount);
    checkRates(dagaPointsRes?.daga_rate);
    // setConvertedPointsRatio({'' : dagaPointsRes?.daga_rate?.division_rate});
    // console.log(dagaPointsRes)
  };

  // check rates
  const checkRates = (rates) => {
    if (rates) {
      const myRates = Object.keys(rates);
      const myRatesValue = Object.values(rates);
      myRates.map((item) => {
        if (item == "division_rate") {
          setConvertedPointsRatio({ divide: myRatesValue[0] });
        } else if (item == "multiply_rate") {
          setConvertedPointsRatio({ multiply: myRatesValue[0] });
        }
      });
    }
  };

  // play now, login in game
  const playNow = async (points) => {
    // console.log((points))
    // // console.log("transfer all ponts");
    // console.log(points?.toString()?.includes("."));
    // console.log( points?.toString()?.split(".")[1]?.length);
    // console.log( points?.toString()?.split(".")[1].length >2);

    // if points
    if (Number(points)) {
      // console.log('Points')
      // all transfer points
      if (Number(points) > Number(ctx.userInfo.balance)) {
        // Not enough balance, Insufficient Balance Please Recharge First
        setError(
          "Tài khoản của bạn không đủ số dư để chuyển điểm vào trò chơi. Vui lòng nạp tiền vào tài khoản."
        );
        // console.log('points greater than user balance');
      } else if (Number(points) < minValue && maxValue == null) {
        // min individual game, Not Max, Please select minimum points
        setError("Vui lòng chọn điểm tối thiểu");
        // console.log('points les than minimum, no maximum');
      } else if (maxValue && Number(points) < minValue) {
        // max value and tp < min value
        setError(
          "Tài khoản của bạn không đủ số dư để chuyển điểm vào trò chơi. Vui lòng nạp tiền vào tài khoản."
        );
        // console.log('points les than minimum, no maximum');
      } else if (
        !singleGameModal.game_id === 'daga' &&
        points?.toString()?.includes(".") &&
        points?.toString()?.split(".")[1]?.length &&
        points?.toString()?.split(".")[1].length > 2
      ) {
        // games have decimal and length greater than 2
        setError("Chỉ chấp nhận hai số thập phân");
        // console.log("Decimal length error");
      } else if (
        Number(points) === Number(ctx.userInfo?.balance) &&
        Number(points) > maxValue
      ) {
        if (singleGameModal.game_id === 'daga') {
          //user balance is greater than DAGA max value
          gameDepositCall(null, maxValue);
          // console.log('Daga maximum value if points are greater than maximum points')
        } else {
          //user balance is greater than GAME max value
          gameDepositCall(
            singleGameModal.id,
            maxValue ? maxValue : Number(points)
          );
          // console.log('Game maximum value if points are greater than maximum points')
        }
      } else {
        if (singleGameModal.game_id === 'daga') {
          // daga decimal , no decimal
          // console.log(typeof(points))
          if (
            Number(points) === Number(ctx?.userInfo?.balance) &&
            points.toString()?.includes(".")
          ) {
            // console.log("no decimal check section")
            //if points has decimal, conversion of points
            let dagaPoints = Math.trunc(points / 30) * 30;
            // console.log('daga have decimal points, convert them and send')
            // converted points more than max, send converted points
            if (dagaPoints > maxValue) {
              //converted points are greater than max value
              gameDepositCall(null, maxValue);
              // console.log('daga have decimal points, convert points greater than max')
            } else {
              //sending converted points
              gameDepositCall(null, dagaPoints);
              // console.log('daga have decimal points, send converted points')
            }
          } else {
            // console.log("no decimal check section")
            //points dont have decimal,calculate if points divisible by 30 and send
            let dagaPoints = Math.trunc(points / 30) * 30;

            gameDepositCall(null, dagaPoints);
            // console.log('daga doesnot have decimal, send points')
          }
        } else {
          //other games deposit
          // console.log("id,points",singleGameModal.id, points )
          gameDepositCall(singleGameModal.id, points);
        }
      }
    } else if (gameBalance && singleGameModal.game_id === 'daga') {
      // daga balance
      setPlayGameState(false);
      // setShowGames(false);
      navigate("/game-links");
    } else {
      // console.log("am in else");
      // console.log("am in elseid,points",singleGameModal.id, points )
      gameDepositCall(singleGameModal.id, points);
    }

    // else if (gameBalance) {
    //   // game balance
    //   gameDepositCall(singleGameModal.id, points);
    // } else if (!gameBalance && !Number(points)) {
    //   // no balance, no points
    //   setError(
    //     "Tài khoản của bạn không đủ số dư để chuyển điểm vào trò chơi. Vui lòng nạp tiền vào tài khoản."
    //   );
    // }
  };

  // play now API call
  const gameDepositCall = async (id = singleGameModal.id, points) => {
    setLoader(true);
    // console.log(points);
    if (singleGameModal.game_id === 'daga') {
      // console.log(points);
      const depositApiRes = await APIDagaDeposit(points);
      gameDepositResponse(depositApiRes);
    } else {
      const depositApiRes = await APIGameDeposit(id, points || 0);
      // console.log("depositApiRes", depositApiRes);
      gameDepositResponse(depositApiRes);
    }
  };

  // play now API response
  const gameDepositResponse = (depositRes) => {
    // console.log(depositRes);
    if (depositRes == "INSUFFICIENT_BALANCE") {
      setError(
        "Tài khoản của bạn không đủ số dư để chuyển điểm vào trò chơi. Vui lòng nạp tiền vào tài khoản."
      );
      setLoader(false);
    } else if (depositRes == "BALANCE_NETWORK_ERROR") {
      setError("LỖI MẠNG");
      setLoader(false);
    } else if (depositRes == "REGISTRATION_NETWORK_ERROR") {
      setError("Lỗi mạng đăng ký");
      setLoader(false);
    } else if (depositRes == "LOGIN_NETWORK_ERROR") {
      setError("Lỗi mạng đăng nhập");
      setLoader(false);
    } else if (depositRes?.message == "PENDING_TRANSACTION") {
      setPendingModal(depositRes);
      setLoader(false);
    } else if (depositRes?.message == "PLEASE_DEPOSIT") {
      setPendingModal(depositRes);
      setLoader(false);
    } else if (depositRes && depositRes?.message == "PENDING_DEPOSIT") {
      setPendingModal(depositRes);
      setLoader(false);
    } else if (depositRes == "DEPOSIT_NETWORK_ERROR") {
      setError("Lỗi mạng tiền gửi");
      setLoader(false);
    } else if (depositRes == "NETWORK_ERROR") {
      setError("Lỗi mạng");
      setLoader(false);
    } else if (depositRes == "FORBIDDEN") {
      setError("CẤM");
      setLoader(false);
    } else if (depositRes == "AMOUNT_NOT_ACCEPTED") {
      setError("Số tiền không được chấp nhận");
      setLoader(false);
    } else if (depositRes?.status) {
      if (singleGameModal.game_id === 'daga') {
        // setDaga(false);
        navigate("game-links");
      } else {
        window.open(depositRes?.link, "_blank");
      }
      ctx.setUserInfo({
        ...ctx.userInfo,
        balance: depositRes?.player_balance,
      });
      closeModal();
      setShowGames && setShowGames(false);
    }

    // if (depositRes && depositRes[0] == "LOGIN_SUCCESS") {
    //   navigate("game-links");
    //   ctx.setUserInfo({
    //     ...ctx.userInfo,
    //     balance: depositRes[1],
    //   });
    // } else if (depositRes == "FORBIDDEN") {
    //   setError("Admin Forbidden");
    //   setLoader(false);
    // } else if (depositRes == "INSUFFICIENT_BALANCE") {
    //   setError("Insufficient Balance");
    //   setLoader(false);
    // } else if (depositRes == "AMOUNT_NOT_ACCEPTED") {
    //   setError("Amount not accepted");
    //   setLoader(false);
    // } else if (depositRes == "NETWORK_ERROR") {
    //   setError("Network");
    //   setLoader(false);
    // } else if (depositRes) {
    //   ctx.setUserInfo({
    //     ...ctx.userInfo,
    //     balance: depositRes[1],
    //   });
    //   window.open(depositRes && depositRes[0], "_blank");
    //   closeModal();
    // } else {
    //   setError("Some Error");
    //   setLoader(false);
    // }
  };

  // Close modal
  const closeModal = () => {
    setSingleGameModal(false);
    setPlayGameState(false);
    setTransferPoints("");
    setGameBalance();
    setError();
    setLoader(false);
    setPendingModal();
    setMinValue();
    setMaxValue();
    setDaga && setDaga(false);
  };

  // play now button enable
  const isButtonEnabled = () => {
    switch (true) {
      case transferPoints && transferPoints?.charAt(0) == "0":
        setError("Vui lòng không nhập số bắt đầu bằng 0");
        return false;
      // if game balance is there no tp, allow play
      case Number(gameBalance) > 0 && !Number(transferPoints):
        // console.log("in this condition");
        // console.log("gamebal > 0 no tp");
        return true;
      //no game balance no tp
      case !Number(gameBalance) && !Number(transferPoints):
        console.log("in this condition");
        // console.log("gamebal ==0 0 no tp");
        return false;
      case singleGameModal.game_id === 'daga' && !Number(gameBalance) && !Number(transferPoints):
        console.log("in this condition");
        // console.log("gamebal ==0 0 no tp");
        return false;
      //daga and tp and tp includes decimal
      case singleGameModal.game_id === 'daga' && transferPoints && transferPoints?.includes("."):
        // console.log("daga decimal");
        setError("Giá trị thập phân không được chấp nhận");
        return false;
      //tp and tp includes decimal and length greater than 2
      case transferPoints &&
        transferPoints?.includes(".") &&
        transferPoints?.split(".")[1]?.length &&
        transferPoints?.split(".")[1].length > 2:
        // console.log(" decimal and lenght greater than 2");
        setError("Chỉ chấp nhận hai số thập phân");
        return false;
      //daga tp min max
      case singleGameModal.game_id === 'daga' &&
        transferPoints &&
        (Number(transferPoints) < minValue ||
          Number(transferPoints) > maxValue):
        // console.log("daga tp min max");
        return false;
      //other games tp is in min and max
      case Number(transferPoints) >= minValue &&
        maxValue &&
        Number(transferPoints) <= maxValue:
        // console.log("amount range");
        return true;
      //tp and minvalue only
      case Number(transferPoints) >= minValue && maxValue == null:
        // console.log("Smaller Min");
        return true;
      default:
        return false;
    }
  };

  // transfer points to main balance
  const transferPointsToMain = async (game, amount) => {
    setbalanceLoader(true);
    try {
      if (game.game_id === 'daga') {
        const res = await APIDagaWithdraw(amount);

        if (res) {
          gameBalanceApi()
        }
      } else {
        const res = await SingleGameWithdrawAPI(
          game.id,
          amount
        );

        if (res) {
          gameBalanceApi()
        }
      }
    } catch (error) {
      setbalanceLoader(false);
      console.error("Error transfering points:", error);
    }
  };

  useEffect(() => {
    // console.log("button enabling");
    if (playGameState && gameBalance?.toString()) {
      setButtonEnabled(isButtonEnabled());
    }
  }, [playGameState, transferPoints, gameBalance]);

  return (
    <>
      <Modal
        open={playGameState ? true : false}
        BackdropComponent={Backdrop}
        BackdropProps={{
          // timeout: 5000,
          onClick: closeModal, // This ensures clicking on the backdrop closes the modal
        }}
      >
        <Box className={styles.modalTopOverlay}>
          <div
            className="modal"
            style={{ width: "100%", position: "relative" }}
          >
            <div className={styles.modalOverlay}>
              <div className={styles.modalWrapper}>
                <div className={styles.playGameheader}>
                  <span>Chuyển điểm</span>
                  {/* <span onClick={closeModal}>
                  <AiOutlineClose size={20} />
                </span> */}
                  <div className={styles.balanceSectionWrapper}>
                    <div className={styles.dbIconWrapper}>
                      <img src={dbIcon} alt="balance Img" />
                    </div>
                    <span>{`${CommaSeperator(ctx?.userInfo?.balance)} K`}</span>
                  </div>
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  <div className={styles.playGameBody}>
                    <div className={styles.playGameBodyDataHeading}>
                      <span>
                        {singleGameModal.game_id === 'daga' && "Daga"}{" "}
                        {singleGameModal && JsonFormate(singleGameModal.name)}
                      </span>
                      <span>
                        Chuyển điểm
                        <span style={{ marginLeft: "3px" }}>(K)</span>
                      </span>
                    </div>
                    <div className={styles.playGameBodyDataValue}>
                      {gameBalance || gameBalance == 0 ? (
                        !balanceloader ? (
                          <div className={styles.gameBalanceWrapper}>
                            { gameBalance > 0 && (
                            <button
                              onClick={() => {
                                transferPointsToMain(
                                  singleGameModal,
                                  gameBalance
                                )
                              }}
                              style={{ cursor: 'pointer', background: 'none', border: 'none' }}
                              disabled={balanceloader}
                            >
                              <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="2" fill="url(#paint0_linear_3526_48869)" />
                                <path d="M15.7435 11.2564H14.6666C14.5238 11.2564 14.3868 11.1997 14.2858 11.0987C14.1849 10.9977 14.1281 10.8608 14.1281 10.7179C14.1281 10.5751 14.1849 10.4382 14.2858 10.3372C14.3868 10.2362 14.5238 10.1795 14.6666 10.1795H15.7435C15.9814 10.1791 16.2095 10.0844 16.3777 9.91621C16.5459 9.74799 16.6406 9.51995 16.641 9.28205V4.97436C16.6406 4.73646 16.5459 4.50841 16.3777 4.3402C16.2095 4.17198 15.9814 4.0773 15.7435 4.07692H4.25634C4.01844 4.0773 3.7904 4.17198 3.62218 4.3402C3.45396 4.50841 3.35929 4.73646 3.35891 4.97436V9.28205C3.35929 9.51995 3.45396 9.74799 3.62218 9.91621C3.7904 10.0844 4.01844 10.1791 4.25634 10.1795H5.33326C5.47607 10.1795 5.61303 10.2362 5.71401 10.3372C5.815 10.4382 5.87173 10.5751 5.87173 10.7179C5.87173 10.8608 5.815 10.9977 5.71401 11.0987C5.61303 11.1997 5.47607 11.2564 5.33326 11.2564H4.25634C3.73283 11.256 3.23086 11.0479 2.86068 10.6777C2.4905 10.3075 2.28236 9.80557 2.28198 9.28205V4.97436C2.28236 4.45084 2.4905 3.94888 2.86068 3.5787C3.23086 3.20851 3.73283 3.00038 4.25634 3H15.7435C16.267 3.00038 16.769 3.20851 17.1392 3.5787C17.5094 3.94888 17.7175 4.45084 17.7179 4.97436V9.28205C17.7175 9.80557 17.5094 10.3075 17.1392 10.6777C16.769 11.0479 16.267 11.256 15.7435 11.2564Z" fill="white" />
                                <path d="M13.2308 16.9998H6.76928C6.24576 16.9994 5.7438 16.7913 5.37362 16.4211C5.00344 16.0509 4.7953 15.5489 4.79492 15.0254V6.41004C4.79492 6.26723 4.85165 6.13027 4.95263 6.02929C5.05361 5.92831 5.19057 5.87158 5.33338 5.87158H14.6667C14.8095 5.87158 14.9465 5.92831 15.0475 6.02929C15.1484 6.13027 15.2052 6.26723 15.2052 6.41004V15.0254C15.2048 15.5489 14.9967 16.0509 14.6265 16.4211C14.2563 16.7913 13.7543 16.9994 13.2308 16.9998ZM5.87185 6.94851V15.0254C5.87222 15.2633 5.9669 15.4914 6.13512 15.6596C6.30334 15.8278 6.53138 15.9225 6.76928 15.9229H13.2308C13.4687 15.9225 13.6968 15.8278 13.865 15.6596C14.0332 15.4914 14.1279 15.2633 14.1283 15.0254V6.94851H5.87185Z" fill="white" />
                                <path d="M9.99992 14.8462C9.9292 14.8464 9.85914 14.8325 9.79382 14.8054C9.7285 14.7783 9.66922 14.7385 9.61941 14.6883L7.46556 12.5344C7.37045 12.4324 7.31867 12.2974 7.32113 12.1579C7.32359 12.0184 7.3801 11.8853 7.47876 11.7866C7.57741 11.688 7.71051 11.6315 7.85001 11.629C7.98951 11.6265 8.12451 11.6783 8.22659 11.7734L9.99992 13.546L11.7733 11.7734C11.8753 11.6783 12.0103 11.6265 12.1498 11.629C12.2893 11.6315 12.4224 11.688 12.5211 11.7866C12.6197 11.8853 12.6763 12.0184 12.6787 12.1579C12.6812 12.2974 12.6294 12.4324 12.5343 12.5344L10.3804 14.6883C10.3306 14.7385 10.2713 14.7783 10.206 14.8054C10.1407 14.8325 10.0706 14.8464 9.99992 14.8462Z" fill="white" />
                                <path d="M9.99989 14.8462C9.85708 14.8462 9.72012 14.7895 9.61914 14.6885C9.51816 14.5875 9.46143 14.4506 9.46143 14.3078V9.28211C9.46143 9.1393 9.51816 9.00235 9.61914 8.90136C9.72012 8.80038 9.85708 8.74365 9.99989 8.74365C10.1427 8.74365 10.2797 8.80038 10.3806 8.90136C10.4816 9.00235 10.5383 9.1393 10.5383 9.28211V14.3078C10.5383 14.4506 10.4816 14.5875 10.3806 14.6885C10.2797 14.7895 10.1427 14.8462 9.99989 14.8462Z" fill="white" />
                                <path d="M15.3846 6.94851H4.61537C4.47256 6.94851 4.3356 6.89177 4.23462 6.79079C4.13363 6.68981 4.0769 6.55285 4.0769 6.41004C4.0769 6.26723 4.13363 6.13027 4.23462 6.02929C4.3356 5.92831 4.47256 5.87158 4.61537 5.87158H15.3846C15.5274 5.87158 15.6644 5.92831 15.7653 6.02929C15.8663 6.13027 15.9231 6.26723 15.9231 6.41004C15.9231 6.55285 15.8663 6.68981 15.7653 6.79079C15.6644 6.89177 15.5274 6.94851 15.3846 6.94851Z" fill="white" />
                                <defs>
                                  <linearGradient id="paint0_linear_3526_48869" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#06BBF7" />
                                    <stop offset="1" stop-color="#17346C" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </button>
                            )}
                            <span
                              className={
                                gameBalance == "KHÔNG ĐƯỢC HỖ TRỢ" ||
                                gameBalance == "LỖI MẠNG" ||
                                gameBalance == "CẤM"
                                  ? styles.balanceError
                                  : styles.balance
                              }
                            >
                              {gameBalance == "LỖI MẠNG"
                                ? "LỖI MẠNG"
                                : gameBalance == "CẤM"
                                ? "CẤM"
                                : gameBalance == "KHÔNG ĐƯỢC HỖ TRỢ"
                                ? "KHÔNG ĐƯỢC HỖ TRỢ"
                                : gameBalance === 0
                                ? 0
                                : CommaSeperator(gameBalance)}
                            </span>

                            <img
                              src={Refresh}
                              alt="Refresh"
                              onClick={(e) => {
                                e.stopPropagation();
                                gameBalanceApi();
                              }}
                            />
                          </div>
                        ) : (
                          <MyLoader size={20} />
                        )
                      ) : (
                        <div className={styles.loaderWrapper}>
                          <MyLoader />
                        </div>
                      )}
                      <div className={styles.pointsContent}>
                        <input
                          type="number"
                          required={Number(gameBalance) <= 0 ? true : false}
                          placeholder={singleGameModal.game_id === 'daga' ? "30" : "1"}
                          value={transferPoints}
                          onChange={(e) => {
                            setTransferPoints(e.target.value);
                            setError();
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.transferAllBtnWrapper}>
                      {!loader &&
                        gameBalance !== null &&
                        gameBalance !== undefined &&
                        gameBalance !== "" && (
                          <div
                            className={styles.transferAllBtn}
                            onClick={() => playNow(ctx.userInfo?.balance)}
                          >
                            Chuyển tất cả điểm
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className={styles.loadingErrorWrapper}>
                  {error && <strong>{error}</strong>}
                  {loader && <MyLoader margin="15px 0 0 0" />}
                </div>

                {singleGameModal.game_id === 'daga' && convertedPointsRatio?.divide ? (
                  <p className={styles.pointsInfo}>
                    {convertedPointsRatio?.divide} = 1
                  </p>
                ) : (
                  !singleGameModal.game_id === 'daga' &&
                  !convertedPointsRatio?.multiply && (
                    <p className={styles.pointsInfo}>30 = 1</p>
                  )
                )}
                {!singleGameModal.game_id === 'daga' && convertedPointsRatio?.multiply && (
                  <p className={styles.pointsInfo}>
                    1 ={" "}
                    {CommaSeperator(
                      convertedPointsRatio?.multiply
                        ? 1 * convertedPointsRatio.multiply
                        : convertedPointsRatio?.divide
                        ? 1 / convertedPointsRatio.divide
                        : ""
                    )}
                  </p>
                )}

                <MaxMinLimit
                  min={minValue}
                  max={maxValue}
                  game={true}
                  transferPoints={transferPoints}
                />

                <PointsConverter
                  daga={daga}
                  transferPoints={transferPoints}
                  convertedPointsRatio={convertedPointsRatio}
                />

                {!loader &&
                  gameBalance !== null &&
                  gameBalance !== undefined &&
                  gameBalance !== "" && (
                    <div
                      className={
                        buttonEnabled?.toString() === "false"
                          ? styles.playGameFooterDisable
                          : styles.playGameFooter
                      }
                    >
                      <button
                        onClick={() => playNow(transferPoints)}
                        disabled={
                          buttonEnabled?.toString() === "false" ? true : false
                        }
                      >
                        Chơi ngay
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {pendingModal && (
        <PendingTransactionModal
          gameTransactionError={pendingModal}
          hideModal={() => {
            closeModal();
            setPendingModal(false);
          }}
        />
      )}
    </>
  );
};

export default PlayGameModal;

// {prevTransactionLoader ? (
//   <MyLoader />
// ) : prevTransactionError ? (
//   <strong style={{ color: "red", textAlign:'center', fontSize:'14px' }}>{prevTransactionError}</strong>
// ) : (
//   !loader && ( gameBalance > 0 || gameBalance == 0 ) && (
//     <div
//       className={
//         buttonEnabled?.toString() === "false"
//           ? styles.playGameFooterDisable
//           : styles.playGameFooter
//       }
//     >
//       <button
//         onClick={() => playNow(transferPoints)}
//         disabled={
//           buttonEnabled?.toString() === "false" ? true : false
//         }
//       >
//         Play Now
//       </button>
//     </div>
//   )
// )}

// if(daga && transferPoints.includes('.')){
//   // if points have decimal value only for DAGA
//   setError("Decimal error");
// } else
