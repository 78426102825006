import getAxiosInstance from "./BaseUrl";

export const notificationsAPI = async () => {
  const BaseUrl = await getAxiosInstance();

  const res = await BaseUrl.get(`player/notifications`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  });
  return res;
};

export const notificationCountAPI = async () => {
  const BaseUrl = await getAxiosInstance();

  const res = await BaseUrl.put(
    `player/notifications/get_count`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    }
  );
  return res;
};
