import CommaSeperator from "../CommaSeperator";
import styles from "./PointsConverter.module.css";
import PointsArrow from "../../assets/PointsArrow.webp";

const PointsConverter = ({
  transferPoints,
  deposit,
  withdraw,
  convertedPointsRatio,
  daga,
  text,
  convertedAmount,
}) => {
  return (
    <div
      className={`${styles.convertionWrapper} ${deposit || withdraw ? styles.depositConvertionWrapper : ""}`}
    >
      <div style={{ fontSize: '16px' }}>{text}</div>
      <div className={styles.convertionContent}>
        {/* { !withdraw && <span>{CommaSeperator(transferPoints ? daga ? Number( Math.trunc(transferPoints / 30) * 30): transferPoints: 0)}</span>}
        { !withdraw && <img src={PointsArrow} alt='arrow' />} */}
        <span>
          {CommaSeperator(
            transferPoints
              ? daga
                ? Number(Math.trunc(transferPoints / 30) * 30)
                : transferPoints
              : 0
          )}
          {(withdraw || deposit) && (
            <span> VND</span>
          )}
        </span>
        {daga && <img src={PointsArrow} alt="arrow" />}
        {daga && (
          <span>
            {CommaSeperator(
              daga
                ? Number(Math.trunc(transferPoints / 30))
                : convertedPointsRatio && convertedPointsRatio?.multiply
                ? transferPoints * convertedPointsRatio.multiply
                : convertedPointsRatio && convertedPointsRatio?.divide
                ? transferPoints / convertedPointsRatio.divide
                : 0
            )}
            {withdraw && <span> VND</span>}
          </span>
        )}
      </div>
    </div>
  );
};

export default PointsConverter;
