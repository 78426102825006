import styles from "./BankAccountManagement.module.css";
import ProfileLayout from "../../layout/ProfileLayout/ProfileLayout";
import { useState, useEffect } from "react";
import { bankListAPI } from "../../helpers/APIs/BankAPIs";
import MyLoader from "../../components/MyLoader";
import AddAccountModal from "../../components/AddAccountModal/AddAccountModal";
import { FaCirclePlus } from "react-icons/fa6";
import supportIcon from "../../assets/SupportIcon/support icon.webp";

const BankAccountManagement = () => {
  const [banks, setBanks] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(null);
  const [addAccountState, setAddAccountState] = useState(false);
  const [maxAccounts, setMaxAccounts] = useState(0);

  // user bank list
  useEffect(() => {
    bankList();
    setMaxAccounts(0);
    setErrorMessage("");
  }, [addAccountState]);

  // bank list API
  const bankList = async () => {
    setLoader(true);
    const allBanks = await bankListAPI();
    if (!allBanks.response?.length) {
      setErrorMessage(
        "Chưa Có Thông Tin Ngân Hàng. Vui Lòng Thêm Ngân Hàng Để Tiếp Tục."
      );
      setBanks(allBanks?.response);
      setMaxAccounts(allBanks?.max_account_count);
    } else {
      setMaxAccounts(allBanks.max_account_count);
      setBanks(allBanks.response);
    }
    setLoader(false);
  };

  const AddBankSection = () => (
    <>
      {
        banks?.length > 0 ? (
          <>
            <div className={styles.bankAccountWrapper}>
              <div className={styles.allBanksOverlay}>
                {errorMessage ? (
                  <div className={styles.errorMsg}>{errorMessage}</div>
                ) : banks && banks?.length ? (
                  <div className={styles.banksSection}>
                    {banks &&
                      banks?.slice(0, banks?.length).map((bank) => {
                        return (
                          <div className={styles.bankCardWrapper} key={bank.id}>
                            <div className={styles.bankCard}>
                              <span className={styles.accountNum}>
                                {bank.account_number}
                              </span>
                              <span className={styles.username}>
                                {bank.User_name}
                              </span>
                              <span className={styles.username}>
                                {bank.bank_name}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <MyLoader />
                  </div>
                )}

                <div
                  className={`${styles.addBnksBtn} ${(banks?.length >= maxAccounts || loader) && styles.disabled}`}
                  onClick={() => {
                    ((banks?.length && banks?.length < maxAccounts && !loader) ||
                      (banks?.length == 0 && !loader)) &&
                      setAddAccountState(true);
                  }}
                >
                  <FaCirclePlus
                    size={18}
                    color={!(banks?.length >= maxAccounts || loader) && "#00C1FE"}
                  />
                  &nbsp;Thêm ngân hàng
                </div>

                <div className={styles.horizontalLine}></div>

                <div className={styles.supportStyles}>
                  <div className={styles.textStyles}>
                    Để Thay Đổi Hoặc Xóa Thông Tin Ngân Hàng Vui Lòng Liên Hệ Chăm Sóc
                    Khách Hàng
                  </div>
                  <button
                    className={styles.supportBtn}
                    onClick={() => window.open("https://direct.lc.chat/13775445/")}
                  >
                    <img
                      src={supportIcon}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    Hỗ Trợ
                  </button>
                </div>
              </div>
            </div>
          </>
        ) :
          <>
            <div className={styles.noAccountWrapper}>
              <div className={styles.allBanksOverlay}>
                {errorMessage ? (
                  <>
                    <div className={styles.errorMsg}>{errorMessage}</div>
                    <div
                      className={`${styles.addBnksBtn} ${(banks?.length >= maxAccounts || loader) && styles.disabled}`}
                      onClick={() => {
                        ((banks?.length && banks?.length < maxAccounts && !loader) ||
                          (banks?.length == 0 && !loader)) &&
                          setAddAccountState(true);
                      }}
                    >
                      <FaCirclePlus
                        size={18}
                        color={!(banks?.length >= maxAccounts || loader) && "#00C1FE"}
                      />
                      &nbsp;Thêm ngân hàng
                    </div>
                  </>
                ) :
                  <div style={{ textAlign: "center" }}>
                    <MyLoader />
                  </div>
                }
              </div>
            </div>
          </>
      }
    </>
  )

  return (
    // <ProfileLayout title='Bank Account Management'>
    <>
      {!addAccountState && (
        <>
          <AddBankSection />
        </>
      )}

      {addAccountState && (
        <>
          {/* Code when add bank has been tick */}
          <AddAccountModal
            show={addAccountState}
            hideModal={() => setAddAccountState(false)}
            maxAccounts={maxAccounts}
          />
        </>
      )}
      {/* </ProfileLayout> */}
    </>
  );
};

export default BankAccountManagement;
