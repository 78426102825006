import { Modal, Popover } from "@mui/material";
import styles from "./LoginModal.module.css";
import Login from "../../pages/Login/Login";

const LoginModal = ({ open, id, anchorEl, handleClose }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: "5px",
      }}
    >
      <div className={styles.popoverStyles}>
        <Login handleClose={handleClose} />
      </div>
    </Popover>
  );
};

export default LoginModal;
