import { Popover } from "@mui/material";
import styles from "./NotificationModal.module.css";
import { useEffect, useState } from "react";
import { notificationsAPI } from "../../helpers/APIs/NotificationAPI";
import getAxiosInstance from "../../helpers/APIs/BaseUrl";
import { formatDistance } from "date-fns";
import MyLoader from "../MyLoader";
import NotificationIcon from "../../assets/NotificationIcon/NotificationIcon";

const NotificationModal = ({ open, id, anchorEl, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const handleNotificationData = async () => {
    setLoading(true);
    try {
      const res = await notificationsAPI();
      if (res?.status) {
        setNotifications(res?.data);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
    }

    setLoading(false);
  };
  const handleNotificationClick = async () => {
    const BaseUrl = await getAxiosInstance();

    await BaseUrl.put(
      `/player/notifications/mark_as_read`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    );
  };
  useEffect(() => {
    if (open) {
      handleNotificationData();
      handleNotificationClick();
    }
  }, [open]);

  const getTimeAgo = (dateString) => {
    const serverDate = new Date(dateString + "Z"); // Adding 'Z' ensures that dateString is treated as UTC
    const now = new Date();
    const timeAgo = formatDistance(serverDate, now, { addSuffix: true });
    return timeAgo;
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: "14px",
      }}
    >
      <div className={styles.popoverStyles}>
        {notifications && notifications.length ? (
          <div className={styles.notificationsOverlay}>
            {notifications &&
              notifications.map((notify) => (
                <div
                  className={`${styles.notificationsWrapper} ${
                    notify?.read
                      ? styles.notificationRead
                      : styles.notificationUnread
                  } `}
                  key={notify?.id}
                >
                  <div className={styles.titleDateContainer}>
                    <h1 className={styles.titleStyling}>{notify?.title}</h1>

                    {notify && notify.updated_at && (
                      <p>{getTimeAgo(notify.created_at)}</p>
                    )}
                  </div>
                  <p className={styles.detailStyling}>{notify?.message}</p>
                </div>
              ))}
          </div>
        ) : (
          <div className={styles.noNotificationWrapper}>
            {loading ? (
              <MyLoader />
            ) : (
              <>
                <div className={styles.noNotificationImageContainer}>
                  <NotificationIcon />
                </div>

                <div className={styles.textStyling}>
                  Hiện tại không có tin nhắn hệ thống mới. Vui lòng quay trở lại
                  sau!
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Popover>
  );
};

export default NotificationModal;
