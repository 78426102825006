import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "./Context/user-context";
import { APIDagaBalance, APIDagaDeposit } from "./APIs/DagaAPI";

export function useDagaGame() {
  const ctx = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dagaLoader, setDagaLoader] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const transferAllPointsInDaga = async () => {
    if (!ctx.user) {
      navigate("/");
    } else if (!ctx?.userInfo?.user_id && !ctx?.userInfo?.user_name) {
      setShowErrorModal(true);
    } else {
      if (Number(ctx?.userInfo?.balance) > 0) {
        setLoading(true);
        const res = await APIDagaDeposit(
          Math.trunc(Number(ctx.userInfo.balance))
        );
        if (res?.status && res?.message === "SUCCESS") {
          ctx.setUserInfo({
            ...ctx.userInfo,
            balance: Number(res.player_balance),
          });
          await getDagaBalance();
          navigate("/game-links");
        } else {
          navigate("/game-links"); // Navigate regardless of success to maintain previous behavior
        }
        setLoading(false);
      } else {
        navigate("/game-links");
      }
    }
  };

  const getDagaBalance = async () => {
    setDagaLoader(true);
    const res = await APIDagaBalance();
    ctx.setDagaBalance(
      res === "FORBIDDEN"
        ? "FORBIDDEN"
        : res === "NETWORK_ERROR"
        ? "NETWORK_ERROR"
        : res?.result
    );
    setDagaLoader(false);
  };

  return {
    transferAllPointsInDaga,
    getDagaBalance,
    loading,
    dagaLoader,
    setDagaLoader,
    showErrorModal,
    setShowErrorModal,
  };
}
