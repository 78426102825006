import React from "react";

export default function ResetPasswordIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clipPath="url(#clip0_567_7242)">
        <mask
          id="mask0_567_7242"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="17"
        >
          <path d="M16 0.5H0V16.5H16V0.5Z" fill={color} />
        </mask>
        <g mask="url(#mask0_567_7242)">
          <path
            d="M10.596 1.69999C9.41563 1.69999 8.28362 2.16888 7.44899 3.00352C6.61435 3.83815 6.14546 4.97016 6.14546 6.15052C6.14539 6.5329 6.19522 6.91366 6.29367 7.28315L1.34841 12.2263C1.30149 12.2723 1.26422 12.3272 1.23877 12.3878C1.21332 12.4484 1.20021 12.5134 1.2002 12.5791V15.0516C1.2002 15.1827 1.25227 15.3084 1.34498 15.4011C1.43768 15.4938 1.56341 15.5459 1.69451 15.5459H4.16735C4.29845 15.5459 4.42418 15.4938 4.51689 15.4011C4.60959 15.3084 4.66167 15.1827 4.66167 15.0516V14.0625H5.65072C5.78182 14.0625 5.90755 14.0104 6.00026 13.9177C6.09296 13.825 6.14504 13.6993 6.14504 13.5682V12.5791H7.13409C7.26519 12.5791 7.39092 12.5271 7.48362 12.4344C7.57633 12.3417 7.62841 12.2159 7.62841 12.0848V11.0958H8.61746C8.68256 11.0962 8.7471 11.0837 8.80736 11.059C8.86762 11.0344 8.92242 10.9981 8.96862 10.9522L9.46335 10.4579C9.83291 10.5547 10.2135 10.603 10.5956 10.6015C11.7759 10.6015 12.9079 10.1326 13.7426 9.29793C14.5772 8.4633 15.0461 7.33129 15.0461 6.15094C15.0461 4.97058 14.5772 3.83857 13.7426 3.00394C12.9079 2.1693 11.7759 1.70041 10.5956 1.70041L10.596 1.69999ZM11.0903 7.13957C10.7968 7.13957 10.51 7.05253 10.266 6.88946C10.022 6.72639 9.83191 6.49462 9.71969 6.22347C9.60746 5.95232 9.57819 5.65398 9.63556 5.36619C9.69293 5.0784 9.83438 4.81409 10.042 4.6067C10.2496 4.39932 10.5141 4.25817 10.8019 4.20113C11.0898 4.14408 11.3881 4.1737 11.6591 4.28623C11.9301 4.39876 12.1617 4.58915 12.3245 4.83331C12.4873 5.07747 12.574 5.36443 12.5737 5.65788C12.5732 6.05101 12.4167 6.42787 12.1386 6.7057C11.8605 6.98352 11.4834 7.13957 11.0903 7.13957Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_567_7242">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
