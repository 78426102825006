import getAxiosInstance from "./BaseUrl";

// file, 
// formData.append("deposit_receipt", file ? file : '');
// console.log(amount)
// console.log(selected_bank_id)
// console.log(reject_previous || false)
// return true
export const APIMakeDepositRequest = async (amount, bank_id, payment_method, payment_method_code, category_id, category_code) => {
  const BaseUrl = await getAxiosInstance();
  var formData = new FormData();
  formData.append("transaction_amount", amount);
  formData.append("bank_id", bank_id);
  // reject_previous && formData.append("reject_previous", reject_previous || false);
  formData.append("payment_method", payment_method);
  formData.append("payment_method_code", payment_method_code);
  formData.append('category_id', category_id)
  formData.append('category_code', category_code)

  const token = localStorage.getItem('auth_token')
  try {
    const res = await BaseUrl.post("/account/deposit", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    });
    if (res.status === 200) {
      return res?.data;
    }
  } catch (e) {
    if (e.response.data.message === "YOU_HAVE_PENDING_TRANSACTION") {
      return "YOU_HAVE_PENDING_TRANSACTION";
    } else if (e.response.data.message === 'FAILD_TO_GET_QR') {
      return "FAILD_TO_GET_QR"
    } else if (e.response.data.message === 'NETWORK_ERROR') {
      return "NETWORK_ERROR"
    } else if (e.response.data.message === 'WRONG_PAYMENT_METHOD') {
      return "WRONG_PAYMENT_METHOD"
    } else if (e.response.data.message === 'BANK_NOT_SUPPORTED') {
      return "BANK_NOT_SUPPORTED"
    } else {
      console.log(e)
      return null
    }
  }
  return null;
}

export const APICheckTransaction = async (token, transactionId) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get("/account/transaction_status/"+transactionId, {
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.data && res.data) {
      if(res.data.account){
        return true;
      }
      return false;
    }
  } catch (e) {
    // console.log(e);
  }
  return false;
}

export const APIGetAllTransactions = async (token) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get("/account/user_transaction", {
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res && res.data && res.data.response && res.data.response.length) {
      return res.data.response;
    }
  } catch (e) {
    // console.log(e);
  }
  return null;
}

export const APILatestTransaction = async (token) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get("/account/latest_transaction", {
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (e) {
    // console.log(e);
  }
  return null;
}

export const APIGetSingleTransaction = async (token, transactionId) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get("/account/transaction/" + transactionId, {
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status === 200 && res.data) {
      return res.data
    }
  } catch (e) {
    // console.log(e);
  }
  return false;
}

export const APIGetCalenderData = async (token) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get("/player/daily/login", {
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res && res.data) {
      return res.data
    }
  } catch (e) {
    // console.log(e);
  }
  return false;
}

export const depositAllowed = async ()=>{
  const BaseUrl = await getAxiosInstance();

  return await BaseUrl.get('player/check/allow/deposit', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`
    }
  });
}
export const singleTransactionStatusCheck = async (id) => {
  const BaseUrl = await getAxiosInstance();

  try {
    const res = await BaseUrl.get(`/account/transaction/status/check/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
      }
    });
    if (res.status === 200 && res.data) {
      return res.data
    }
  }
  catch (e) {
    console.log(e);
    // return e
  }
  return false;
}
export const getProviderDetailApi = async (id) => {
  const BaseUrl = await getAxiosInstance();

  try {
    // const res = await BaseUrl.get(`/bank/get_payment_provider_details`, {
    const res = await BaseUrl.get(`/bank/get_payment_category_details`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
      }
    });
    if (res?.status === 200 && res?.data) {
      return res.data
    }
  }
  catch (e) {
    console.log(e);
    // return e
  }
  return false;
}