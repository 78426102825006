import style from "../../components/PopularSection/PopularSection.module.css";
import DividerLeft from "../../assets/HomeImages/DividerLeft.webp";
import DividerRight from "../../assets/HomeImages/DividerRight.webp";
import Game3 from "../../assets/HomeImages/Section2/Game3.webp";
import Game4 from "../../assets/HomeImages/Section2/Game4.webp";
import Game5 from "../../assets/HomeImages/Section2/Game5.webp";
import Game6 from "../../assets/HomeImages/Section2/Game6.webp";
import Game7 from "../../assets/HomeImages/Section2/Game7.webp";
import Game8 from "../../assets/HomeImages/Section2/Game8.webp";

import MyLoader from "../MyLoader";
import { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import UserContext from "../../helpers/Context/user-context";
import { useDagaGame } from "../../helpers/useDagaGame";
import { getGameCategories, getDagaGameCategories } from "../../helpers/APIs/GameAPIs";
import ShowGames from "../Header/HeaderComponents/SecondaryHeader/ShowGames/ShowGames";
import SingleGameList from "./SingleGameListing/SingleGameListing";

export const PopularSection = () => {
  const [activeCatId, setActiveCatId] = useState();
  const [gameLink, setGameLink] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [activeCategoryId, setActiveCategoryId] = useState("");

  const [gameCategories, setGameCategories] = useState();
  const [dagaGameCategories, setDagaGameCategories] = useState();
  const [showGames, setShowGames] = useState(false);
  const [dagaGames, setDagaGames] = useState(null);
  const [games, setGames] = useState(null);
  const [gameTransactionError, setGameTransactionError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [selected, setSelected] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();
  const navigate = useNavigate(UserContext);

  const [agencyModal, setAgencyModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const ctx = useContext(UserContext);
  const token = localStorage.getItem("auth_token");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const {
    transferAllPointsInDaga,
    getDagaBalance,
    loading,
    dagaLoader,
    setDagaLoader,
    showErrorModal,
    setShowErrorModal,
  } = useDagaGame();

  const { data, isLoading, error } = useQuery("categories", getGameCategories);



  // useEffect(() => {
  //   if (token) {
  //     getDagaBalance();
  //   } else { }
  // }, [token]);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const openGameModal = async (game) => {
    if (!ctx.user) {
      navigate('/login');
    } else {
      setPlayGameState(true);
      setSingleGameModal(game);
    }
  };

  const getDagaCategories = async () => {
    setLoader(true);
    try {
      const response = await getDagaGameCategories();

      // console.log('this daga games: ', response)
      setDagaGameCategories(response?.games);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setDagaGameCategories([]);
      console.error("Error fetching categories:", error);
    }
  };

  const getOtherGameCategories = async () => {
    setLoader(true);
    try {
      const response = await getGameCategories();

      // console.log('this daga games: ', response)
      console.log(response?.games)
      setGameCategories(response?.games);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setGameCategories([]);
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getDagaCategories();
    getOtherGameCategories();
  }, []);

  // const { data, isLoading, error } = useQuery('categories', getGameCategories, getDagaGameCategories, {
  //   refetchOnWindowFocus: false
  // });

  // useEffect(() => {
  //   // Once the data is loaded, update the state
  //   if (data) {
  //     setGameCategories(data?.games);
  //     setDataLoading(isLoading)
  //     // ctx.setCategoryGamesContext(data);
  //   }
  //   if (isLoading) { }
  //     setDataLoading(isLoading);
  //   // Handle errors if any
  //   if (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }, [data, isLoading, error]);

  const handleCategoryClick = (category) => {
    setActiveCategoryId(category.id);
    setGames(category?.games);
    setGameLink(category?.link);
  };

  // useEffect(() => {
  //   if (gameCategories?.games?.length > 0) {
  //     setActiveCategoryId(gameCategories?.games[0]?.id);
  //     ctx.setGamesRates(gameCategories?.rates);
  //     // console.log(gameCategories)
  //   } else if (gameCategories?.games?.length == 0) {
  //     setErrorMsg("Không tìm thấy trò chơi nào");
  //   } else if (gameCategories == "NETWORK_ERROR") {
  //     setErrorMsg("Lỗi mạng");
  //   }
  // }, [gameCategories]);

  // useEffect(() => {
  //   if (activeCategoryId) {
  //     setGames(
  //       gameCategories?.games?.filter((category) => {
  //         return Number(category.id) === Number(activeCategoryId);
  //       })[0].game_items
  //     );
  //   }
  // }, [activeCategoryId]);

  // useEffect(() => {
  //   if (gameCategories?.games?.length === 0 || dagaGameCategories?.games?.length === 0) {
  //     setErrorMsg("Không tìm thấy trò chơi nào");
  //   } else if (gameCategories === "NETWORK_ERROR" || dagaGameCategories === "NETWORK_ERROR") {
  //     setErrorMsg("Lỗi mạng");
  //   }
  // }, [gameCategories, dagaGameCategories]);



  const referral = ctx.refCode && ctx.refCode;
  const games1 = [
    { game_id: 1, image: Game3 },
    { game_id: 2, image: Game4 },
    { game_id: 3, image: Game5 },
    { game_id: 4, image: Game6 },
    { game_id: 5, image: Game7 },
    { game_id: 6, image: Game8 },
  ];

  const gameImgClick = () => {
    window.open(
      ctx.refCode
        ? `https://${referral}.sv368vn.today/register.html`
        : `https://www.sv368.bio/register`,
      "_blank"
    );
  };

  const onClickBanner = (game) => {
    console.log(game)
  }

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.sectionTitle}>
          <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
            <path d="M319.887 3.00002L317 0.113264L314.113 3.00002L317 5.88677L319.887 3.00002ZM317 2.50002L0.5 2.49999L0.5 3.49999L317 3.50002L317 2.50002Z" fill="white" />
          </svg>

          <div className={style.title}>Phổ Biến</div>

          <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
            <path d="M0.113251 2.99999L3 5.88674L5.88675 2.99999L3 0.113236L0.113251 2.99999ZM319.5 2.50002L3 2.49999L3 3.49999L319.5 3.50002L319.5 2.50002Z" fill="white" />
          </svg>
        </div>
        <div className={style.sectionContent}>
          <div className={style.gameList}>

            {/* DAGA Game Section */}
            {dagaGameCategories?.length > 0 ? (
              dagaGameCategories?.map((item, index) => (
                <div key={item.id}>
                  {item?.game_items && item?.game_items?.map((game, index) => (
                    <div className={style.gameitem}>
                      <div key={index} className={style.imageContainer}>
                        <img
                          onClick={() => onClickBanner(game)}
                          className={style.gameImage}
                          src={game?.icon_rectangle}
                          alt={game?.game_id}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )
              )) : (
              <MyLoader />
            )}

            {/* Other Games Section */}
              {gameCategories?.map((item, index) => (
                <div key={index}>
                  {item?.game_items && item?.game_items?.map((game, index) => (
                    <div className={style.gameItem}>
                      <div key={index} className={style.imageContainer}>
                        <img
                          onClick={() => onClickBanner()}
                          className={style.gameImage}
                          src={game?.icon_rectangle}
                          alt={game?.game_id}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>

          {/* <div className={style.gameList}>
          {games.slice(3, 6)?.map((game, index) => {
            return <img key={game?.id} className={style.gameImage} src={game?.image} alt={`Game${game?.id}`} onClick={gameImgClick} />
          })}
                  </div> */}
        </div>
      </div>
      {/* {
        showGames && (
          <ShowGames
            showGames={showGames}
            setShowGames={setShowGames}
            games={games}
            errorMsg={errorMsg}
            setSelected={setSelected}
            selected={selected}
            setGames={setGames}
            setDagaGames={setDagaGames}
          />
        )
      } */}

      {activeCategoryId && (
        <SingleGameList
          games={games}
          setGames={setGames}
          activeCatId={activeCategoryId}
        />
      )}
    </>
  );
};

export default PopularSection;


{/* {games1?.map((game, index) => {
            return (
              <div key={game?.id} className={style.gameItem}>
                <div className={style.imageContainer}>
                  <img
                    className={style.gameImage}
                    src={game?.image}
                    alt={`Game ${game?.id}`}
                    onClick={gameImgClick}
                  />
                  <button className={style.overlayButton}>Chơi ngay</button>
                </div>
              </div>

            );
          })} */}