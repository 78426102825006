import { Helmet } from "react-helmet";

const HelmetComponent = ({ title, description }) => {
  return (
    <Helmet>
      <title>
        {title
          ? title
          : "GA368 - ĐẠI LÝ ĐÁ GÀ TRỰC TIẾP THOMO SV388, CAMPUCHIA"}
      </title>
      <meta
        name="description"
        content={
          description
            ? description
            : "GA368 - đăng ký tài khoản chơi đá gà trực tiếp Sv388 thomo & campuchia tại SV368"
        }
      />
    </Helmet>
  );
};

export default HelmetComponent;
