import { useContext, useState } from "react";
import styles from "./SingleGameList.module.css";
import { useNavigate } from "react-router-dom";
import PlayGameModal from "../../HomeComponents/PlayerGameModal/PlayerGameModal";
import UserContext from "../../../helpers/Context/user-context";
import LazyGameImage from "./LazyGameImage";
import SvenusImg from '../../../assets/HomeImages/Section1/svenus-img.webp'

const SingleGameList = ({ games, gameLink }) => {
  // console.log(gameLink);
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();

  const navigate = useNavigate();
  const ctx = useContext(UserContext);
  // open game Modal
  const openGameModal = async (game) => {
    if (!ctx.user) {
      navigate("/login");
    } else {
      setPlayGameState(true);
      setSingleGameModal(game);
    }
  };

  return (

    <div className={styles.sectionContent}>
      {
        <div className={styles.imageContainer}>
          {games && games?.game_id == 'daga'
            ? games?.map((game, index) => (
              <div
                key={game.game_id}
              // onClick={() =>
              //   gameLink &&
              //   window.open(
              //     ctx.refCode
              //       ? gameLink
              //       : `https://www.sv368.casino/register`,
              //     "_blank"
              //   )
              // }
              >
                <img className={styles.dagaTitleImage} src={SvenusImg} alt="SvenusImg" />
                <button
                  onClick={() => openGameModal(game)}
                  className={styles.overlayButton}
                >
                  Chơi ngay
                </button>
              </div>

            ))
            : games && (
              <div className={styles.noImage}>
                <h4>Không tìm thấy trò chơi nào</h4>
              </div>
            )}
        </div>
      }
      {playGameState && (
        <PlayGameModal
          playGameState={playGameState}
          setPlayGameState={setPlayGameState}
          singleGameModal={singleGameModal}
          setSingleGameModal={setSingleGameModal}
        />
      )}
    </div>
  );
};

export default SingleGameList;
